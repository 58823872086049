import {useEffect, useState} from 'react'
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import Dropdown from 'react-dropdown';
import { useParams } from 'react-router-dom';
import Radio from 'components/radio'
import 'react-dropdown/style.css';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import InputColor from 'react-input-color';
import Cookies from 'js-cookie';


const ManageRole = () => {

  const [formData, setFormData] = useState({
    merchant_id: '',
    merchant_name: '',
    merchid:'',
    primary_color:'#00000000',
    logo_file:'',
    stylesheet_url:'',
    stylesheet_text:'',
  });

  const [defaultOption, setDefaultOption] = useState('')
  const [error, setError] = useState('');
  const { id } = useParams();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUrl, setFileUrl] = useState('');
  const [buttonLabel, setButtonLabel] = useState('Add Merchant');

  useEffect(() => {
    __init()
  },[])

  const __init = async () => {
    if (id !== undefined) {
      setButtonLabel('Update Merchant')
      await getMerchantDetails(id);
    }
  }

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    await setSelectedFile(file);
  };

  const handleUpload = async () => {
    const formSubmit = new FormData();
    formSubmit.append('merchant_name', formData.merchant_name);
    formSubmit.append('merchant_id', formData.merchant_id);
    formSubmit.append('merch_id', formData.merchid);
    formSubmit.append('primary_color', formData.primary_color)
    formSubmit.append('stylesheet_url', formData.stylesheet_url)
    formSubmit.append('stylesheet_text', formData.stylesheet_text)
    if (selectedFile) {
      formSubmit.append('file', selectedFile);
    } else {
      formSubmit.append('logo_file', formData.logo_file);
    }
    var token =  Cookies.get('token');
    return fetch(`${process.env.REACT_APP_APIURL}/update_merchant`, {
      method: 'POST',
      credentials: 'include',
      headers: {'Content-Type':'application/json',Authorization: `Bearer ${token}`},
      body:formSubmit
    })
    .then(data => data.json())
    .then(data => {
      if (data[0]['status'] === "success") {
        window.location.href = "/admin/merchant_list"
      }
      else {
        setError(data[0]['message'])
      }
    })
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleChangePrimaryColor = (color) => {
    setFormData({ ...formData, primary_color: color['hex'] });
  }

  async function getMerchantDetails(merchid) {
    var token =  Cookies.get('token');
    return fetch(`${process.env.REACT_APP_APIURL}/get_merchant_detail`, {
      method: 'POST',
      credentials: 'include',
      headers: {'Content-Type':'application/json',Authorization: `Bearer ${token}`},
      body: JSON.stringify({id: merchid})
    }).then(response => response.json())
      .then(data => {
        setFormData({ ...data, merchid: merchid });
        if(data.logo_file) {
          getImageUrl(data.logo_file, setFileUrl)
        }
      })
  }

  const getImageUrl = async (image, seturl) => {
    var token =  Cookies.get('token');
    try {
      const response = await fetch(image);
      if (response.ok) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        seturl(url);
        } else {
        console.error('Error fetching image URL:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching image URL:', error);
    }
  }

  return (
    <div className="mt-2 mb-16 flex h-full w-full px-2 md:mx-0 md:px-0 lg:mb-10 justify-center">
      <div className="text-red-600">{error}</div>
    {/* Sign in section */}
    <div className="mt-[5vh] w-full max-w-full flex-col md:pl-4 lg:pl-0 xl:max-w-[420px]">
      {error !== '' &&
        <div className="text-red-500 mx-2 ml-1 text-base">{error}</div>
      }
      {/* Email */}

      <InputField
        variant="auth"
        extra="mb-3"
        label="Customer id*"
        placeholder="Customer id"
        name="merchant_id"
        id="merchant_id"
        type="text"
        value={formData.merchant_id}
        onChange={e => handleChange(e)}
      />

      <InputField
        variant="auth"
        extra="mb-3"
        label="Customer name*"
        placeholder="Customer name"
        name="merchant_name"
        id="merchant_name"
        type="text"
        value={formData.merchant_name}
        onChange={e => handleChange(e)}
      />

      <div className="py-4">  
      <InputField
        variant="auth"
        extra="mb-3"
        label="Main Logo url"
        placeholder="Logo url"
        name="logo_file"
        id="logo_file"
        type="text"
        value={formData.logo_file}
        onChange={e => handleChange(e)}
      />
      </div>

      <div className="py-4 hidden"><label for="mainlogo" >Upload Main Logo:</label>
      <input type="file" accept="image/*" onChange={handleFileChange} name="mainlogo" />
      {fileUrl !== '' &&
        <img src={fileUrl} alt="Selected" style={{ maxWidth: '50%' }} className="py-4" />
      }
      </div>
      <div className="py-4 flex align-center">
      Choose Primary Color:  
      <InputColor
        initialValue={formData.primary_color?formData.primary_color:"#000000"}
        onChange={handleChangePrimaryColor}
        placement="right"
        className="mx-4"
      />
      </div>
      <div className="py-4">  
      <InputField
        variant="auth"
        extra="mb-3"
        label="Theme stylesheet url for Chat conversatio page"
        placeholder="Stylesheet url"
        name="stylesheet_url"
        id="stylesheet_url"
        type="text"
        value={formData.stylesheet_url}
        onChange={e => handleChange(e)}
      />
      </div>
      <div className="py-4">  
      <label>Theme stylesheet texts for Chat conversatio page
      <textarea
        className="mb-3 flex w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-grey-900"
        placeholder="Stylesheet text"
        name="stylesheet_text"
        id="stylesheet_text"
        rows="4"
        onChange={e => handleChange(e)}
        value={formData.stylesheet_text !== undefined?formData.stylesheet_text:""}
      />
      </label>
      </div>
      <button onClick={handleUpload} type="submit" className="linear mt-2 w-full rounded-xl bg-green-900 py-[12px] text-base font-medium text-white transition duration-200  m-background">
      {buttonLabel}
      </button>
    </div>
  </div>
  );
};

export default ManageRole;
