export const SET_ALL_CONVERSATIONS = 'SET_ALL_CONVERSATIONS';
export const SET_CONVERSATION_ENGAGED_LIST = 'SET_CONVERSATION_ENGAGED_LIST';
export const SET_ERRORS_LIST = 'SET_ERRORS_LIST';
export const SET_MERCHANTS_LIST = 'SET_MERCHANTS_LIST';
export const SET_USERS_LIST = 'SET_USERS_LIST';
export const SET_FILTERS = 'SET_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';
export const RESET_ALL_CONVERSATIONS = 'RESET_ALL_CONVERSATIONS';
export const RESET_CONVERSATION_ENGAGED_LIST = 'RESET_CONVERSATION_ENGAGED_LIST';
export const GET_CUSTOMER_CONFIG = 'GET_CUSTOMER_CONFIG';


// TOPIC ACTIONS
export const SET_TOPIC_ANALYSIS_VISUALIZATION = "SET_TOPIC_ANALYSIS_VISUALIZATION";
export const SET_TOPIC_SELECTION_FOR_VISUALIZATION = "SET_TOPIC_SELECTION_FOR_VISUALIZATION";
export const SET_TOPIC_SELECTED_FOR_VISUALIZATION = "SET_TOPIC_SELECTED_FOR_VISUALIZATION";

// TOPIC TRENDS ACTION
export const SET_TOPIC_TREND_ANALYSIS_VISUALIZATION = 'SET_TOPIC_TREND_ANALYSIS_VISUALIZATION';
export const SET_TOPIC_SELECTION_FOR_TREND_ANALYSIS_VISUALIZATION = 'SET_TOPIC_SELECTION_FOR_TREND_ANALYSIS_VISUALIZATION';
export const SET_TOPIC_SELECTED_FOR_TREND_ANALYSIS_VISUALIZATION = 'SET_TOPIC_SELECTED_FOR_TREND_ANALYSIS_VISUALIZATION';

// TOPIC FLOW
export const SET_TOPIC_FLOW_ANALYSIS_VISUALIZATION = 'SET_TOPIC_FLOW_ANALYSIS_VISUALIZATION';
export const RESET_TOPIC_FLOW_ANALYSIS_VISUALIZATION = 'RESET_TOPIC_FLOW_ANALYSIS_VISUALIZATION';

export const SET_SELECTED_VALUE = 'SET_SELECTED_VALUE';
export const SET_SELECTED_TIMEZONE = 'SET_SELECTED_TIMEZONE';
export const SET_LOADING_LIST = 'SET_LOADING_LIST';
