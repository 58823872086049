const ChatIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="30" viewBox="0 0 29 30" fill="none">
      <path d="M25.3503 22.6321C27.041 21.1253 27.9983 19.1169 27.9983 17.0326C27.9983 14.8176 26.9614 12.7461 25.0785 11.1993C23.2459 9.69433 20.8203 8.86542 18.248 8.86542C15.6758 8.86542 13.2502 9.69433 11.4179 11.1994C9.53498 12.7461 8.49805 14.8176 8.49805 17.0326C8.49805 19.2477 9.53498 21.3192 11.4179 22.866C13.2502 24.3711 15.6758 25.1999 18.248 25.1999C18.5908 25.1999 18.9356 25.1848 19.2776 25.1547L19.8618 25.6611C21.8745 27.4052 24.4485 28.3653 27.1117 28.3654H27.998V26.4787L27.7385 26.2192C26.7144 25.1921 25.9029 23.9732 25.3503 22.6321ZM21.0229 24.3214L19.8506 23.3053L19.4597 23.3533C19.0577 23.4024 18.6531 23.4271 18.248 23.4271C13.8495 23.4271 10.2708 20.5585 10.2708 17.0326C10.2708 13.5067 13.8495 10.6381 18.248 10.6381C22.6466 10.6381 26.2253 13.5067 26.2253 17.0326C26.2253 18.7893 25.3489 20.4293 23.7576 21.6505L23.2471 22.0422L23.4826 22.7089C23.9654 24.0706 24.6749 25.3408 25.5812 26.4658C23.8934 26.1841 22.316 25.442 21.0229 24.3214Z" fill="white"/>
      <path d="M3.85498 18.4757C4.68468 17.4254 5.33613 16.2459 5.78326 14.9844L6.01704 14.321L5.50699 13.9295C4.00638 12.778 3.17995 11.2325 3.17995 9.57775C3.17995 6.25256 6.55971 3.54732 10.714 3.54732C13.7702 3.54732 16.4071 5.01149 17.5876 7.1094C17.8068 7.09876 18.027 7.09278 18.2481 7.09278C18.6923 7.09278 19.1324 7.11433 19.5685 7.15743C19.1123 5.97552 18.32 4.89659 17.2307 4.00192C15.4822 2.56562 13.1682 1.7746 10.714 1.7746C8.25993 1.7746 5.94585 2.56562 4.19728 4.00192C2.39807 5.47987 1.40723 7.46007 1.40723 9.57775C1.40723 11.561 2.31309 13.4719 3.91397 14.9094C3.39154 16.1666 2.62817 17.3095 1.66688 18.2736L1.40723 18.5332V20.3882H2.29359C3.93265 20.388 5.54916 20.0063 7.01519 19.2734C6.85444 18.6641 6.76025 18.0391 6.73427 17.4095C5.84446 17.931 4.86984 18.2919 3.85498 18.4757Z" fill="white"/>
    </svg>
  );
};

export default ChatIcon;
