import React, { useMemo } from "react";
import Card from "components/card";
import EditIcon from '../../../../assets/svg/editIcon.svg';
import DeleteIcon from '../../../../assets/svg/deleteIcon.svg'


const CheckTable = (props) => {
  const { columnsData, tableData } = props;
  return (
    <Card extra={"w-full h-full sm:overflow-auto shadow-none rounded-none mt-1 relative"}>
      {props.isLoading &&
        <div className="loading-container"> <div className="loading-div"></div></div>
      }
      <div className="pt-4 overflow-x-scroll">
        <table
          className="w-full tablelist"
          variant="simple"
          color="gray-500"
          mb="24px"
        >
          <thead>
            <tr>
              <th
                className="pl-[2px] text-center" width="80"
              >
                <div className="px-[10px] text-[#555555] bg-[#ECECEC] rounded text-sm font-medium text-white font-poppins leading-[30px] py-1 whitespace-nowrap ">
                  No.
                </div>
              </th>
              <th
                className="pl-[2px] text-center"
              >
                <div className="text-left px-[10px] text-[#555555] bg-[#ECECEC] rounded text-sm font-medium text-white font-poppins leading-[30px] py-1 whitespace-nowrap ">
                  Customer
                </div>
              </th>
              <th
                className="pl-[2px] text-center w-[200px]"
              >
                <div className={`px-[10px] text-[#555555] bg-[#ECECEC] rounded text-sm font-medium text-white font-poppins leading-[30px] py-1 whitespace-nowrap min-w-[300px]`}>
                  Actions
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr data-index={index + ((props.page - 1) * 25)} key={index}>
                <td className="pl-2 text-s my-2 border-l rounded-l-md">
                  <div className=" py-2 pl-2">
                    {((index + 1) + ((props.page - 1) * 25)) < 10 ? '0' + ((index + 1) + ((props.page - 1) * 25)) : ((index + 1) + ((props.page - 1) * 25))}
                  </div>
                </td>
                <td className="pl-2 text-s">
                  <div className=" py-2 pl-2 -ml-3">
                    {row.name}
                  </div>
                </td>
                <td className="pl-2 text-s border-r  rounded-r-md">
                  <div className=" flex justify-start items-center gap-3  p-2 -ml-3 ">
                    <div className="border rounded flex gap-2 items-center cursor-pointer px-3">
                      <img src={EditIcon} alt="icon" />
                      <a href={`/admin/merchants_details/${row.id}`} className="m-text">Edit</a>
                    </div>
                    {/* <a href={`/admin/merchants_details/${row.id}`} className="text-green-900 underline">Edit</a>  */}
                    <div onClick={() => props.confirm_delete(row.id)} className="cursor-pointer m-text border rounded  px-3 flex items-center gap-2 align-middle">
                      <img src={DeleteIcon} alt="icon" />
                      <span>Delete</span>
                    </div>
                    {/* <span onClick={() => props.confirm_delete(row.id)} className="cursor-pointer text-green-900 underline">Delete</span> */}
                  </div>
                </td>
              </tr>
            ))}
            {/* {columnsData === false &&
              <tr><td colSpan="6" className="text-center text-lg">loading ...</td></tr>
            } */}
            {columnsData === true && tableData.length === 0 &&
              <tr><td colSpan="6" className="text-center text-lg">No Result</td></tr>
            }
          </tbody>
        </table>
      </div>
      
      <div className="m-auto my-12 flex gap-2">
        {props.page > 1 &&
          <button className="font-poppins font-normal text-base text-[#555555] bg-[#ECECEC] flex items-center justify-center gap-2.5 rounded py-1.5 pr-2 pl-3 w-16 h-10 " onClick={() => props.setPage(props.page - 1)}>Prev</button>
        }
        {(props.page * 25) < props.total &&
          <button className="font-poppins font-normal text-base text-[#555555] bg-[#ECECEC] flex items-center justify-center gap-2.5 rounded py-1.5 pr-2 pl-3 w-16 h-10 " onClick={() => props.setPage(props.page + 1)}>Next</button>
        }
      </div>

    </Card>
  );
};

export default CheckTable;