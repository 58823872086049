import {useEffect, useState} from 'react'
import InputField from "components/fields/InputField";
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { calculateTimeUntilMidnight } from '../../utils/utils'; 


export default function SignIn() {

  const navigage = useNavigate();
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState('');
  const [isDisabled, setIsDisabled] = useState(false)


  async function loginUser(credentials) {
    return fetch(`${process.env.REACT_APP_APIURL}/login`, {
      method: 'POST',
      credentials: 'include',
      xhrFields: { withCredentials: true},
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }

  const handleSubmit = async e => {
    setIsDisabled(true)
    e.preventDefault(); 
    try {
    const token = await loginUser({
      username,
      password
    });
 
    const expirationTime = calculateTimeUntilMidnight();
    if (token !== undefined && token !== '' && token !== null && token.length !== 0) {
      if (token[0]['status'] === 'success') {
        localStorage.removeItem('filters')
        Cookies.set('token', token[0]['token'], { expires: new Date(Date.now() + expirationTime) });
        localStorage.setItem('role',token[0]['role'])
        localStorage.setItem('permission',token[0]['permission'])
        localStorage.setItem('merchant',token[0]['merchant'])
        localStorage.setItem('firstname',token[0]['firstname'])
        localStorage.setItem('lastname',token[0]['lastname'])
        window.location.href = '/admin/default';
      }
      else {
        setError(token[0]['error'])
      }
    }
    else {
      setError("Invalid username and password. Please try again.")
    }
    setIsDisabled(false)
  }
  catch(e) {
    setIsDisabled(false)
    setError("Exception in backend. Please try again later.")
  }
    
  }

  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your username and password to sign in!
        </p>
        {error !== '' &&
          <div className="text-red-500 mx-2 ml-1 text-base">{error}</div>
        }
        {/* Email */}
        <form onSubmit={handleSubmit}>
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email*"
          placeholder="username"
          id="email"
          type="text"
          onChange={e => setUserName(e.target.value)}
        />

        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Min. 8 characters"
          id="password"
          type="password"
          onChange={e => setPassword(e.target.value)}
        />

        <div className="mb-4 flex items-center justify-end px-2">
          <a
            className="text-xl font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href="/auth/forgot_password"
          >
            Forgot Password?
          </a>
        </div>
        
        <button type="submit" className="linear mt-2 w-full rounded-xl bg-green-900 py-[12px] text-base font-medium text-white transition duration-200 dark:bg-green-900 dark:text-white  dark:active:bg-green-700 disabled:opacity-50" disabled={isDisabled} >
          Sign In
        </button>
        </form>
      </div>
    </div>
  );
}
