import React from 'react'

export default function SelectionTabs(props) {
  const { selectedTab, updateFilterValue, merchantColors } = props;
  const { primaryColor, tertiaryColor, seventhColor } = merchantColors && merchantColors;
  return (
    <div>
      <ul className='flex gap-3'>
        <li
          className={`${selectedTab === 'daily' ? 'border rounded' : ''}  py-1 px-3  font-poppins font-medium text-base cursor-pointer`}
          onClick={() => updateFilterValue({ reporttype: 'daily' })}
          style={{
            background: selectedTab === 'daily' ? ((seventhColor && seventhColor !== '#ffffff') ? seventhColor : '#FBEFFC'): '#ffffff',
            //: '#FBEFFC'
            borderColor: selectedTab === 'daily' && (primaryColor ? primaryColor : '#6A1F70'),
            color: selectedTab === 'daily' ? (primaryColor ? primaryColor : '#6A1F70') : '#939393',
          }}
        >Daily</li>
        <li
          className={`${selectedTab === 'weekly' ? 'border rounded' : ''}  py-1 px-3  font-poppins font-medium text-base cursor-pointer`}
          onClick={() => updateFilterValue({ reporttype: 'weekly' })}
          style={{
            background: selectedTab === 'weekly' ? ((seventhColor && seventhColor !== '#ffffff') ? seventhColor : '#FBEFFC') : '#ffffff',
            borderColor: selectedTab === 'weekly' && (primaryColor ? primaryColor : '#6A1F70'),
            color: selectedTab === 'weekly' ? (primaryColor ? primaryColor : '#6A1F70') : '#939393',
          }}
        >Weekly</li>
        <li
          className={`${selectedTab === 'monthly' ? 'border rounded' : ''}  py-1 px-3  font-poppins font-medium text-base cursor-pointer`}
          onClick={() => updateFilterValue({ reporttype: 'monthly' })}
          style={{
            background: selectedTab === 'monthly' ? ((seventhColor && seventhColor !== '#ffffff') ? seventhColor : '#FBEFFC') : '#ffffff',
            borderColor: selectedTab === 'monthly' && (primaryColor ? primaryColor : '#6A1F70'),
            color: selectedTab === 'monthly' ? (primaryColor ? primaryColor : '#6A1F70') : '#939393',
          }}
        >Monthly</li>
        <li
          className={`${selectedTab === 'quarterly' ? 'border rounded' : ''}  py-1 px-3  font-poppins font-medium text-base cursor-pointer`}
          onClick={() => updateFilterValue({ reporttype: 'quarterly' })}
          style={{
            background: selectedTab === 'quarterly'? ((seventhColor && seventhColor !== '#ffffff') ? seventhColor : '#FBEFFC') : '#ffffff',
            borderColor: selectedTab === 'quarterly' && (primaryColor ? primaryColor : '#6A1F70'),
            color: selectedTab === 'quarterly' ? (primaryColor ? primaryColor : '#6A1F70') : '#939393',
          }}
        >Quarterly</li>
        <li
          className={`${selectedTab === 'yearly' ? 'border rounded' : ''}  py-1 px-3 font-poppins font-medium text-base cursor-pointer`}
          onClick={() => updateFilterValue({ reporttype: 'yearly' })}
          style={{
            background: selectedTab === 'yearly' && seventhColor && seventhColor !== '#ffffff' && seventhColor,
            borderColor: selectedTab === 'yearly' && (primaryColor ? primaryColor : '#6A1F70'),
            color: selectedTab === 'yearly' ? (primaryColor ? primaryColor : '#6A1F70') : '#939393',
          }}
        >Yearly</li>
      </ul>
    </div>
  )
}
