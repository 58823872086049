import { default as ReactSelect } from "react-select";
import DropdownIndicator from "components/DropdownIndicator/DropdownIndicator";
import { components } from "react-select";

const ShowHideColumns = (props) => {

  function Option(props) {
    return (
      <div>
        <components.Option {...props}>
          <div className='flex justify-between'>
            <label>{props.label}</label>
            <input
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}
              className="multiple_option"
            />
          </div>
  
        </components.Option>
      </div>
    );
  };  

  return (
    <div className=''>
      <ReactSelect
        options={props.formatColumnOptions()}
        isMulti
        hideSelectedOptions={false}  // removes those options from the dropdown which are already selected
        closeMenuOnSelect={false}
        controlShouldRenderValue={false}  // is to stop render of values selected in the dropdown
        components={{
          Option, DropdownIndicator
        }}
        onChange={(value) => { props.handleColumnSelection(value) }}
        // allowSelectAll={true}
        placeholder="Show columns"
        styles={props.colorStyles}
        value={props.selectedColumnsToDisplay}
      />
    </div>

  );
};

export default ShowHideColumns;