import NoResult from '../../assets/svg/noResults.svg'

const NoResultFound = (props) => {
  const { resetFilters, merchantColors, isSelectedMerchant } = props;

  return <div className="w-full h-[100vh] flex justify-center items-center">
    <div className='flex flex-col items-center -translate-y-20'>
      <img src={NoResult} alt='icon'
        className='w-24 h-24'
      />
      <h4 className='font-semibold font-poppins text-2xl text-[#3B3B3B] mt-4 leading-6'>No results</h4>
      <p className=' text-[#555555] text-base font-poppins font-medium mt-2 leading-6'>Please, reset filters or try later</p>
      <button
        className='w-32 h-12 rounded text-white text-base font-poppins font-medium leading-6 mt-8'
        onClick={resetFilters}
        style={{ 
          background: merchantColors.primaryColor ? merchantColors.primaryColor : !isSelectedMerchant ? '#6A1F70' : '#FFFFFF',
          border: isSelectedMerchant && !merchantColors.primaryColor ? '1px solid #6A1F70' : 'none',
          color: isSelectedMerchant && !merchantColors.primaryColor && '#6A1F70',
          }}
      >Reset Filters</button>
    </div>
  </div>
}

export default NoResultFound;