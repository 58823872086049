import { useEffect, useState, useRef } from 'react'
import Filters from "../../../components/filters";

import CheckTable from "views/admin/default/components/CheckTable";

import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { setFilters, getConversationEngagedList } from 'store/actions';
import SelectionTabs from 'components/selectionTabs/SelectionTabs';
import AreaChart, { options } from 'components/charts/AreaChart';
import NoResultFound from 'components/noResultFound/NoResultFound';
import ListItemCount from '../../../components/listItemCount/ListItemCount';
import { getUserConfig, getAllFilterValues, getAllLandingPages, getConversatinCSV, getChatConversationChartMetrics } from 'utils/utils';
import toGetColorCode from 'hooks/useToGetColorCode';
import ShowHideColumns from 'components/tableInfo/ShowHideColumns';
import DownloadTableData from 'components/tableInfo/DownloadTableData';
import NoResult from '../../../components/noResultFound/NoResult'


var records_list = [];


const TurnChat = () => {

  const [landingPage, setLandingPage] = useState([]);
  const [page, setPage] = useState(1);
  const [columnsDataCheck, setColumnsDataCheck] = useState(false);
  const [isAsending, setIsAsending] = useState(false)
  const [xaxis, setXaxis] = useState([]);
  const [filterChange, setFilterChange] = useState(false);
  const headers = useSelector((state) => state.tableHeaders);
  const feedback_headers = useSelector((state) => state.feedbackTableHeaders);
  const transcript_headers = useSelector((state) => state.TranscriptTableHeaders);
  const [noData, setNoData] = useState(false);

  const [areaChartData, setAreaChartData] = useState(options);
  const [selectedTab, setSelectedTab] = useState('weekly');
  const defaultColumnsToDisplay = localStorage.getItem('columns_for_table') ? JSON.parse(localStorage.getItem('columns_for_table')) : formatColumnOptions();

  const [selectedColumnsToDisplay, setSelectedColumnsToDisplay] = useState(defaultColumnsToDisplay);
  const [resetFilters, setResetFilters] = useState(false);

  const dispatch = useDispatch();
  const conversationEngagedSlice = useSelector((state) => state.turnChat);
  const total = conversationEngagedSlice.total ? conversationEngagedSlice.total : 0;
  let tableList = conversationEngagedSlice.conversations ? conversationEngagedSlice.conversations : [];
  const isTableDataLoading = conversationEngagedSlice.isLoading;
  const tableDataPageNumber = conversationEngagedSlice.pageNumber || 1;

  const filters = useSelector((state) => state.filters);

  const totalRowsToDisplayInTable = conversationEngagedSlice.total;
  const hasMoreDataToLoadInTable = (isTableDataLoading || (conversationEngagedSlice?.conversations && (conversationEngagedSlice.conversations.length === totalRowsToDisplayInTable))) ? false : true;
  const selectedMerchant = useSelector((state) => state.selectedValue);
  const customerConfig = useSelector((state) => state.customerConfig);
  const selectedTimezone = useSelector((state) => state.selectedTimezone);

  const primaryColor = customerConfig.configuration && customerConfig.configuration['primary_color'];
  const secondaryColor = toGetColorCode(primaryColor, 3, 'white');
  const tertiaryColor = toGetColorCode(primaryColor, 5, 'white');
  const seventhColor = toGetColorCode(primaryColor, 7, 'white');

  const [requiredColorForPage, setRequiredColorForPage] = useState({
    primaryColor: primaryColor,
    secondaryColor: secondaryColor,
    tertiaryColor: tertiaryColor,
    seventhColor: seventhColor
  })

  const isSelectedMerchant = selectedMerchant === 'All' || selectedMerchant === '' || selectedMerchant === null ? false : true


  useEffect(() => {
    filters.reporttype = 'weekly'
    getAllLandingPages(filters.merchant, setLandingPage);
    sendRequestToBackend(1);
  }, [])

  useEffect(() => {
    localStorage.setItem('filters', JSON.stringify(filters))
  }, [filterChange])



  function updateFilterValue(obj) {
    dispatch(setFilters(obj))
    setFilterChange(true)
    if(obj.reporttype){
      setSelectedTab(obj.reporttype)
    }
  }

  useEffect(() => {
    if (filterChange === true) {
      setFilterChange(false);
      setPage(1);
      dispatch({
        type: 'RESET_CONVERSATION_ENGAGED_LIST',
        payload: {
          data: {
            page: 'turnChat'
          },
        }
      })
      sendRequestToBackend(1, true)
      getAllLandingPages(filters.merchant, setLandingPage);
    }
  }, [filterChange])

  function changePage(page_no) {
    sendRequestToBackend(page_no, true);
  }

  async function sendRequestToBackend(page_no = '', is_by_filter_change) {
    let object = await getAllFilterValues(page, filters, page_no);

    getChatConversationChartMetrics(object, setXaxis, setAreaChartData, areaChartData, "chatturns");
    object["page_type"] = "engage";
    let toUpdate = {
      "isLoading": tableList.length ? false : true,
      pageNumber: page_no,
    }

    if (tableDataPageNumber != 1 || is_by_filter_change) {
      toUpdate.isLoading = true;
    }

    if (toUpdate.isLoading) {
      dispatch({
        type: 'UPDATE_CONVERSATION_ENGAGEMENT_LIST',
        payload: {
          page_no,
          dataIdentifier: 'turnChat',
          toUpdate: {
            "isLoading": true,
          }
        },
      })
      dispatch(getConversationEngagedList('turnChat', object));

    }
  }

  async function sortFunction(field) {
    let asending = true;
    await setIsAsending((prevValue) => {
      asending = prevValue;
      return prevValue;
    });

    await setIsAsending(!asending)
    updateFilterValue({ 'sort': (asending ? "ASC" : "DESC"), 'sorting': field })
  }

  function formatColumnOptions() {

    const columnsOptions = headers?.filter((col) => col.key != "conversation_id")?.map((col, index) => {
      return { id: index, value: col.key, label: col.label }
    })
    return columnsOptions

  }

  async function handleResetFilters() {
    await setResetFilters(true);
    setResetFilters(false);
    setFilterChange(true)
  }

  const fetchMoreTableData = async () => {
    setPage((prevPage) => prevPage + 1);
    // sendRequestToBackend(page + 1);
  }

  useEffect(() => {
    changePage(page);
  }, [page]);

  function handleColumnSelection(value) {
    value = value.sort((a, b) => headers.findIndex((header) => header.key == a.value) - headers.findIndex((header) => header.key == b.value))
    if (!value || !value.length) {
      localStorage.removeItem('columns_for_table')
    } else {
      localStorage.setItem('columns_for_table', JSON.stringify(value))
    }
    setSelectedColumnsToDisplay(value)
  }


  async function getMerchantConfiguration(merchant_value) {
    const config_values = await getUserConfig(merchant_value);
  
    if (config_values !== null && config_values !== undefined) {
      const primaryColor = config_values['primary_color'];
      const secondaryColor = toGetColorCode(primaryColor, 3, 'white');
      const tertiaryColor = toGetColorCode(primaryColor, 5, 'white');
      const seventhColor = toGetColorCode(primaryColor, 7, 'white');

      await setRequiredColorForPage({ ...requiredColorForPage, primaryColor: primaryColor, secondaryColor: secondaryColor, seventhColor: seventhColor, tertiaryColor: tertiaryColor })
    }

    if(merchant_value.length == 0){
      await setRequiredColorForPage({ ...requiredColorForPage, primaryColor: '', secondaryColor: '', seventhColor: '', tertiaryColor: '' })
    }
  }

  async function updateValueforChart(obj) {
    dispatch(setFilters(obj));
    let object = await getAllFilterValues(page, filters, 1);
    object.reporttype = obj.reporttype;
    getChatConversationChartMetrics(object, setXaxis, setAreaChartData, areaChartData, "chatturns");
    setSelectedTab(obj.reporttype)
  }



  useEffect(() => {
    if (selectedMerchant !== 'All') {
      setRequiredColorForPage({ ...requiredColorForPage })
      // getMerchantConfiguration(customerConfig.configuration, selectedMerchant)
      // getMerchantConfiguration([])

      if (selectedMerchant === "") {
        updateFilterValue({
          merchant: ""
        })
      } else {
        updateFilterValue({
          merchant: [{ label: selectedMerchant, value: selectedMerchant }]
        })
      }

      const primaryColor = customerConfig.configuration && customerConfig.configuration['primary_color'];
      const secondaryColor = toGetColorCode(primaryColor, 3, 'white');
      const tertiaryColor = toGetColorCode(primaryColor, 5, 'white');
      const seventhColor = toGetColorCode(primaryColor, 7, 'white');
      setRequiredColorForPage({ primaryColor: primaryColor, secondaryColor: secondaryColor, seventhColor: seventhColor, tertiaryColor: tertiaryColor })
    } else {
      // getMerchantConfiguration({}, [])
      setRequiredColorForPage({ ...requiredColorForPage, primaryColor: '', secondaryColor: '', seventhColor: '', tertiaryColor: '' })
      updateFilterValue({
        merchant: ""
      })
    }

  }, [selectedMerchant, customerConfig.configuration, selectedTimezone])




  const colorStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: (isSelected || isFocused) && requiredColorForPage.primaryColor ? requiredColorForPage.primaryColor : 'transparent',
        color: (isSelected || isFocused) && requiredColorForPage.primaryColor ? '#FFFFFF' : '#000000',
      };
    },
  };

  return (
    <div>
      {/* Card widget */}
      <div className="abc pb-5 pt-0 mx-auto px-2">
        <Filters
          filters={filters}
          setFilters={(obj) => updateFilterValue(obj)}
          landingPage={landingPage}
          total={total}
          filterChange={filterChange}
          resetState={resetFilters}
          merchantColors={requiredColorForPage}
          isSelectedMerchant={isSelectedMerchant}
        />
      </div>

    {
      isTableDataLoading ? <div className="absolute bg-white w-4/5 h-full opacity-70 -translate-x-30"> <div className="loading-div"></div></div> : null
    }
    {
      // isTableDataLoading ? <div className="absolute bg-white w-4/5 h-full opacity-70 -translate-x-30"> <div className="loading-div"></div></div> :
      tableList.length ? <>
        {/* <div className=" w-1/4 pt-10 pb-0">
<Dropdown options={['weekly', 'daily', 'monthly', 'yearly']} value={filters.reporttype} onChange={(e) => {updateFilterValue({reporttype: e.value})}} placeholder="Select an option" className="font-poppins font-medium text-sm text-secondaryColor" />
</div> */}
        <SelectionTabs
          selectedTab={selectedTab}
          updateFilterValue={updateValueforChart}
            merchantColors={requiredColorForPage}
        />
        <div
          // className={`h-[300px] ${filters.reporttype === 'daily'?"w-full":"md:w-2/4"} pt-10 pb-0`}
            className={`h-[300px] pt-10 pb-0 toolbarPosition`}
        >
          {xaxis && <>
            <AreaChart
              options={areaChartData}
              merchantColors={requiredColorForPage}
              fileName="turn-per-chat"
            />
          </>

          }
        </div>

        <div className="mt-5 grid grid-cols-1 gap-1">
          <div className="flex justify-between">
            <div className=''>
              <ListItemCount total={total} merchantColors={requiredColorForPage} />
            </div>
            <div className='flex gap-2 z-30'>
                  <ShowHideColumns 
                  formatColumnOptions={formatColumnOptions} 
                  handleColumnSelection={handleColumnSelection}
                  selectedColumnsToDisplay={selectedColumnsToDisplay}
                  colorStyles={colorStyles}
                  />
                  <DownloadTableData 
                    requiredColorForPage={requiredColorForPage} 
                    isSelectedMerchant={isSelectedMerchant} 
                    headers={headers}
                    csvType="engage"
                    filters={filters}
                    label="Conversation"
                    setNoData={setNoData}
                  />
                  <DownloadTableData 
                    requiredColorForPage={requiredColorForPage} 
                    isSelectedMerchant={isSelectedMerchant} 
                    headers={transcript_headers}
                    csvType="engage"
                    filters={filters}
                    label="Transcripts"
                    type="transcripts"
                    setNoData={setNoData}
                    />
                  <DownloadTableData 
                    requiredColorForPage={requiredColorForPage} 
                    isSelectedMerchant={isSelectedMerchant} 
                    headers={feedback_headers}
                    csvType="engage"
                    filters={filters}
                    label="Feedback"
                    type="feedback"
                    setNoData={setNoData}
                  />
                </div>
              </div>
         </div>

            <div>

              {!(!isTableDataLoading && tableList && tableList.length == 0) && <CheckTable
                columnsToShow={selectedColumnsToDisplay}
                columnsData={!isTableDataLoading}
                tableData={tableList}
                // setPage={(page) => { setPage(page); changePage(page) }}
                total={total}
                page={page}
                sortFunction={sortFunction}
                filters={filters}
                isLoading={isTableDataLoading}
                fetchMoreData={fetchMoreTableData}
                totalRowsToDisplay={totalRowsToDisplayInTable}
                hasMoreDataToLoad={hasMoreDataToLoadInTable}
                merchantColors={requiredColorForPage}
                selectedTimezone={filters.timezone}
                isSelectedMerchant={isSelectedMerchant}
              />}
                {noData &&
                  <NoResult setNoData={setNoData} />
                }
              
            </div>
          </> :
          null
    }
      {
        !isTableDataLoading && tableList && tableList.length == 0 &&
        <NoResultFound
          resetFilters={handleResetFilters}
          merchantColors={requiredColorForPage}
          isSelectedMerchant={isSelectedMerchant}
        />
      }
        </div>
  );
};

export default TurnChat;