
const ListItemCount = (props) => {
    const {total, merchantColors} = props;
    return(
        <h4 className="font-poppins font-small text-l m-text">
          <span className="text-[#1B1B1D]" >Showing results for </span>  
          <span className="text-[#6A1F70]" style={{color: merchantColors.primaryColor}}> {total} conversations</span>
        </h4>
    )
};

export default ListItemCount;
