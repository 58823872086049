/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";

import routes from "routes.js";

const Sidebar = ({ open, onClose }) => {
  return (
    <div
      className={`sm:none duration-175 linear fixed top-[84px] overflow-y-scroll bg-secondaryColor w-64 !z-50 flex h-[90vh] flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50  ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute top-4 right-4 block cursor-pointer xl:hidden text-white z-[90] p-0 m-0 leading-[0px] text-xl"
        onClick={onClose}
      >
        x
      </span>
      {/* Nav item */}

      <ul className="mb-auto flex flex-col gap-16">
        <Links routes={routes} />
        <label className="pl-4 font-poppins text-white text-sm font-medium">© {1900 + new Date().getYear()} All Rights reserved</label>
      </ul>
      

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
