import { useEffect, useState } from 'react'
import CheckTable from "views/admin/useraccount/components/CheckTable";
import Cookies from 'js-cookie';
import DeleteConfirmation from 'components/deleteConfirmation/DeleteConfirmation';
import { useSelector, useDispatch } from 'react-redux';

const UserList = () => {

  const [tableList, setTableList] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [columnsDataCheck, setColumnsDataCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAsending, setIsAsending] = useState(false);
  const [showWarning, setShowWaring] = useState(false);
  const selectedValue = useSelector((state) => state.selectedValue);

  useEffect(() => {
    getAllUsers({ page_no: page })
  }, [])

  async function sortFunction(field) {
    let asending = true;
    await setIsAsending((prevValue) => {
      asending = prevValue;
      return prevValue;
    });

    await setIsAsending(!asending)
  }

  function changePage(page_no) {
    getAllUsers({ page_no: page_no })
  }

  function getAllUsers(object) {
    setIsLoading(true)
    if (selectedValue.length > 0) {
      object['customer'] = selectedValue;
    }
    var token = Cookies.get('token');
    fetch(`${process.env.REACT_APP_APIURL}/users_list`, {
      method: 'post',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify(object)
    }).then(response => response.json())
      .then(data => {
        setIsLoading(false);
        setColumnsDataCheck(true)
        setTableList(data[0]['record'])
        setTotal(data[0]['total'])
        setIsLoading(false)
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function confirmDelete(userid) {
    setIsLoading(true)

    var token = Cookies.get('token');
    fetch(`${process.env.REACT_APP_APIURL}/delete_user`, {
      method: 'post',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify({ id: userid })
    }).then(response => response.json())
      .then(data => {
        getAllUsers({ page_no: page });
        setIsLoading(false)
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false)
      });
  }

  useEffect(() => {
    getAllUsers({ page_no: 1 })
  }, [selectedValue])

 
  return (
    <>
      {/* { 
        showWarning ? <>
            <div className='w-screen absolute left-0 top-0 z-10 h-full bg-[black] opacity-40 flex justify-center items-center'></div>
            <DeleteConfirmation onClosePopup={setShowWaring} />
         </> : null
      } */}
      <div className="mt-0 grid grid-cols-1 gap-1">
        <div className='flex items-end justify-end'>
          <a href="/admin/user_account" className="p-2 bg-green-900 text-white w-fit rounded m-background">Add New User</a>
        </div>
        <div className='w-full'>
          <CheckTable
            columnsData={columnsDataCheck}
            tableData={tableList}
            setPage={(page) => { setPage(page); changePage(page) }}
            total={total}
            page={page}
            sortFunction={sortFunction}
            isLoading={isLoading}
            confirm_delete={(id) => confirmDelete(id)}
          />
        </div>
      </div>


    </>
  );
}; 

export default UserList;
