
import Chart from "react-apexcharts";
import ZoomInIcon from '../../assets/svg/zoom-in-fill.svg';
import ZoomOutIcon from '../../assets/svg/zoom-out-fill.svg';


const AreaChart = (props) => {
  let { options, merchantColors, fileName } = props;


  let graphColor = '#6A1F70';
  if (merchantColors.tertiaryColor && merchantColors.tertiaryColor !== '#ffffff'){
    graphColor = merchantColors.tertiaryColor
  }
  
  options = {
    ...options,
    colors: [graphColor],
    chart: {
      ...options.chart, 
      toolbar: {
        ...options.chart.toolbar,
        export: {
          ...options.chart.toolbar.export,
          csv: {
            filename: fileName || undefined,
            columnDelimiter: ',',
            headerCategory: 'category',
            headerValue: 'value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString()
            }
          },
          svg: {
            filename: fileName || undefined,
          },
          png: {
            filename: fileName || undefined,
          }
        }
      }
    },
    series: [{
      ...options.series[0],
      name: fileName,
      data:options.series[0]?.data
    }],
  }

  console.log("chart data 22", options.series)

  return (
    <>
      <Chart
        options={options}
        type="area"
        width='100%'
        height="100%"
        series={options.series}
      />
    </>
  );
};

export default AreaChart;



export const options = {
  series: [{
    data: []
  }],
  chart: {
    id: 'area-datetime',
    type: 'area',
    // height: 400,
    zoom: {
      autoScaleYaxis: true
    },
    toolbar: {
      // show: true,
      offsetX: 0,
      offsetY: 0,
      tools: {
        download: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-5 -5 24 24"><path fill="#000000" d="m8 6.641l1.121-1.12a1 1 0 0 1 1.415 1.413L7.707 9.763a.997.997 0 0 1-1.414 0L3.464 6.934A1 1 0 1 1 4.88 5.52L6 6.641V1a1 1 0 1 1 2 0v5.641zM1 12h12a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2z"/></svg>`,
        selection: false,
        zoom: false,
        zoomin: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#000000" d="M17.5 10c0 1.71-.572 3.287-1.536 4.548l4.743 4.745a1 1 0 0 1-1.32 1.497l-.094-.083l-4.745-4.743A7.5 7.5 0 1 1 17.5 10ZM10 5.5a1 1 0 0 0-1 1V9H6.5a1 1 0 1 0 0 2H9v2.5a1 1 0 1 0 2 0V11h2.5a1 1 0 1 0 0-2H11V6.5a1 1 0 0 0-1-1Z"/></svg>`,
        zoomout: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#000000" d="M17.5 10a7.5 7.5 0 1 0-2.952 5.964l4.745 4.743l.094.083a1 1 0 0 0 1.32-1.497l-4.743-4.745A7.468 7.468 0 0 0 17.5 10Zm-4-1a1 1 0 1 1 0 2h-7a1 1 0 1 1 0-2h7Z"/></svg>`,
        pan: false,
        reset: false,
      },
      export: {
        csv: {
          filename: undefined,
          columnDelimiter: ',',
          headerCategory: 'category',
          headerValue: 'value',
          dateFormatter(timestamp) {
            return new Date(timestamp).toDateString()
          }
        },
        svg: {
          filename: undefined,
        },
        png: {
          filename: undefined,
        }
      },
      // autoSelected: 'zoom'
    },
  },
  // export: {
  //   fileName: 'my-file-name'
  // },
  grid: {
    show: false,
    borderColor: 'gray',
    opacity: 0.2,
    strokeDashArray: 5,
    position: 'back',
    row: {
      opacity: 0.5,
      colors: undefined
    }
  },
  stroke: {
    // curve: 'smooth',
    width: 2
  },
  // colors: [merchantColors.secondaryColor],
  dataLabels: {
    enabled: false
  },
  markers: {
    size: 0,
    style: 'hollow',
  },
  xaxis: {

  },
  tooltip: {
    x: {
      format: 'dd MMM yyyy'
    },
    // y: {
    //   formatter: undefined,
    //   title: {
    //     formatter: (seriesName) => '%',
    //   },
    // },
  },
  // noData: {
  //   text: "there is not data",
  //   align: 'center',
  //   verticalAlign: 'middle',
  //   offsetX: 0,
  //   offsetY: 0,
  //   style: {
  //     color: undefined,
  //     fontSize: '14px',
  //     fontFamily: undefined
  //   }
  // }
};


// download: '<img src="https://cdn.icon-icons.com/icons2/2348/PNG/512/download_icon_143099.png"  width="20">',
//   selection: false,
//     zoom: false,
//       zoomin: '<img src="https://cdn-icons-png.flaticon.com/512/61/61442.png">',
//         zoomout: '<img src="https://cdn-icons-png.flaticon.com/512/159/159096.png" class="areaChat__icon-zoomOut" width="40"/>',