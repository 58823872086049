import {useEffect, useState} from 'react'
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

export default function Logoutn() {

  const navigage = useNavigate();

  useEffect(() => {
    Cookies.remove('token');
    localStorage.removeItem('filters')
    navigage('/auth/sign-in');
  }, []);

  return (
    <div>Logout</div>
  );
}
