import React from 'react';
import DeleteWarning from '../../assets/svg/deleteWarning.svg';

export default function DeleteConfirmation(props) {
  const { onClosePopup, dataToDelete, onDelete } = props;
  return (
    <>
    <div className='w-screen fixed left-0 top-0 z-[50] h-full bg-[black] opacity-40 flex justify-center items-center'></div>
      <div className='flex flex-col gap-8 p-10 bg-white items-center w-[25rem] h-[10.5rem] rounded shadow-[0_1px_4px_0px_rgba(0,0,0,0.25)] z-50 -translate-24' style={{position: 'absolute', 'top': '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
        <div className="text-xl bold">No Data Found!</div>
        <button
          className='w-32 h-12 bg-[#6A1F70] rounded text-white text-base font-poppins font-medium leading-6 m-background'
          onClick={() => {props.setNoData(false)}}
          // onClick={onClosePopup(false)}
        >Cancel
        </button>
      </div>
    </>
  )
}
