import React, { useMemo } from "react";
import { useEffect, useState } from 'react'
import Card from "components/card";
import ActionIcon from '../../../../assets/svg/checkIcon.svg';
import DeleteIcon from '../../../../assets/svg/deleteIcon.svg';
import EditIcon from '../../../../assets/svg/editIcon.svg';
import DeleteConfirmation from "components/deleteConfirmation/DeleteConfirmation";




const CheckTable = (props) => {
  const { columnsData, tableData } = props;
  const [userRole, setUserRole] = useState('');
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [showWarning, setShowWaring] = useState(false);
  const [userTODelete, setUserToDelete] = useState(null);
  // const [deleteUserInfo, setDeleteUserInfo] = useState({});


  useEffect(() => {
    var role = localStorage.getItem('role');
    setUserRole(role)
  }, [])

  return (
    <Card extra={"w-full h-full sm:overflow-auto shadow-none rounded-none mt-1 relative"}>
      {props.isLoading &&
        <div className="loading-container"> <div className="loading-div"></div></div>
      }
      <div className="pt-4 userCheckTable overflow-x-auto min-h-[500px] ">
        <table
          className="w-full tablelist"
          variant="simple"
          color="gray-500"
          mb="24px"
        >
          <thead>
            <tr>
              <th
                className="pl-[2px] text-center"
              >
                <div className="text-sm px-[10px] text-[#555555] bg-[#ECECEC] rounded font-medium text-white font-poppins leading-[30px] py-1 whitespace-nowrap">
                  No.
                </div>
              </th>
              <th
                className="pl-[2px] text-center"
              >
                <div className="text-sm px-[10px] text-[#555555] bg-[#ECECEC] rounded font-medium text-white font-poppins leading-[30px] py-1 whitespace-nowrap min-w-[100px]">
                  First name
                </div>
              </th>
              <th
                className="pl-[2px] text-center"
              >
                <div className={`text-sm px-[10px] text-[#555555] bg-[#ECECEC] rounded font-medium text-white font-poppins leading-[30px] py-1 whitespace-nowrap min-w-[200px]`}>
                  Last name
                </div>
              </th>
              <th
                className="pl-[2px] text-center"
              >
                <div className={`text-sm px-[10px] text-[#555555] bg-[#ECECEC] rounded font-medium text-white font-poppins leading-[30px] py-1 whitespace-nowrap min-w-[200px]`}>
                  Email
                </div>
              </th>
              <th
                className="pl-[2px] text-center"
              >
                <div className={`text-sm px-[10px] text-[#555555] bg-[#ECECEC] rounded font-medium text-white font-poppins leading-[30px] py-1 whitespace-nowrap min-w-[200px]`}>
                Customer
                </div>
              </th>
              <th
                className="pl-[2px] text-left w-full border-5"
              >
                <div className={`text-sm px-[10px] text-[#555555] bg-[#ECECEC] rounded font-medium text-white font-poppins leading-[30px] py-1 whitespace-nowrap min-w-[350px] `}>
                  Actions
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr
                className=""
                data-index={index + ((props.page - 1) * 25)}
                key={index}
              >
                <td className="text-sm text-[#1B1B1D] border-l rounded-l-md">
                  <div className="pl-2">
                    {((index + 1) + ((props.page - 1) * 25)) < 10 ? '0' + ((index + 1) + ((props.page - 1) * 25)) : ((index + 1) + ((props.page - 1) * 25))}
                  </div>
                </td>
                <td className="pl-2 text-sm text-[#1B1B1D]">
                  <div className="pl-2 -ml-3 ">
                    {row.firstname}
                  </div>
                </td>
                <td className="pl-2 text-sm text-[#1B1B1D]">
                  <div className="pl-2 -ml-3 ">
                    {row.lastname}
                  </div>
                </td>
                <td className="pl-2 text-sm text-[#1B1B1D]">
                  <div className="pl-2 -ml-3 truncate">
                    {row.email}
                  </div>
                </td>
                <td className="pl-2 text-sm text-[#1B1B1D]">
                  <div className="pl-2 -ml-3">{
                    row.role === "admin" ? "Super admin" : (row.role === "merchant") ? 'Customer admin' : "User"
                  }
                  </div>
                </td>
                <td className="pl-2 text-sm text-[#1B1B1D] border-r rounded-r-md">
                  <div className="flex justify-start p-2 -ml-3">
                      <div className="whitespace-nowrap flex flex-nowrap gap-2">
                        <div className="border rounded flex gap-2 items-center cursor-pointer px-3">
                          <img src={EditIcon} alt="icon" />
                          <a href={`/admin/user_account/${row.id}`} className="m-text">Edit</a>
                        </div>
                        {row.role !== 'admin' &&
                          <div className="border rounded flex gap-2 items-center cursor-pointer px-3">
                            <img src={ActionIcon} alt="icon" />
                            <a href={`/admin/assign_lp/${row.id}`} className="m-text">Assign</a>
                          </div>
                        }
                        {deleteIndex !== row.id &&
                          <div
                            onClick={() => {
                              // setDeleteIndex(row.id)
                              setUserToDelete(row)
                              setShowWaring(true)
                            }}
                            className="cursor-pointer m-text border rounded px-3 flex gap-2 align-middle">
                            <img src={DeleteIcon} alt="icon" />
                            <span>Delete</span>
                          </div>
                        }
                        {/* {deleteIndex === row.id &&
                          <div className="flex gap-2 ml-2">
                            <span 
                            onClick={() => {
                              // props.confirm_delete(row.id)
                              // setUserToDelete(row)
                              // setShowWaring(true)
                            }} 
                            className="cursor-pointer flex justify-center items-center w-[30px] h-[30px] text-xs text-white rounded-full m-background"
                            >&#10004;</span>
                            <span 
                            onClick={() => setDeleteIndex(-1)} 
                            className="cursor-pointer flex justify-center items-center w-[30px] h-[30px] text-s text-white rounded-full m-background"
                            >x</span>
                          </div>
                        } */}
                      </div>
                  </div>
                </td>
              </tr>
            ))}
            {/* {columnsData === false &&
              <tr><td colSpan="6" className="text-center text-lg">loading ...</td></tr>
            } */}
            {columnsData === true && tableData.length === 0 &&
              <tr><td colSpan="6" className="text-center text-lg">No Result</td></tr>
            }
          </tbody>
        </table>
      </div>
      <div className="m-auto my-12 flex gap-2">
        {props.page > 1 &&
          <button className="font-poppins font-normal text-base text-[#555555] bg-[#ECECEC] flex items-center justify-center gap-2.5 rounded py-1.5 pr-2 pl-3 w-16 h-10 " onClick={() => props.setPage(props.page - 1)}>Prev</button>
        }
        {(props.page * 25) < props.total &&
          <button className="font-poppins font-normal text-base text-[#555555] bg-[#ECECEC] flex items-center justify-center gap-2.5 rounded py-1.5 pr-2 pl-3 w-16 h-10 " onClick={() => props.setPage(props.page + 1)}>Next</button>
        }
      </div>

      {
        showWarning ? <>
          <div className='w-screen absolute left-0 top-0 z-10 h-full bg-[black] opacity-40 flex justify-center items-center'></div>
          <DeleteConfirmation dataToDelete={userTODelete} onClosePopup={setShowWaring} onDelete={props.confirm_delete} />
        </> : null
      }
    </Card>
  );
};

export default CheckTable;