import { useEffect, useState, useRef } from 'react'
import DownloadIcon from '../../assets/svg/downloadIcon.svg';
import { CSVLink } from "react-csv";
import { getConversatinCSV, getFeedbackCSV, getTranscriptsCSV } from '../../utils/utils';

let current_date = new Date().toISOString().slice(0, 10);

const DownloadTableData = (props) => {
  
  const {requiredColorForPage, isSelectedMerchant, filters, csvType, headers, label, type, setNoData} = props;
  const csvLinkEl = useRef(null);
  const [csvData, setCsvData] = useState([])
  const [isDownloading, setIsDownloading] = useState(false)


  const downloadReport = async () => {
    setIsDownloading(true)
    const data = await getConversatinCSV(1, filters, setCsvData, setIsDownloading, csvLinkEl, csvType, setNoData);
  }

  const downloadFeedback = async () => {
    setIsDownloading(true)
    const data = await getFeedbackCSV(1, filters, setCsvData, setIsDownloading, csvLinkEl, csvType, setNoData);
  }

  const downloadTranscripts = async () => {
    setIsDownloading(true)
    const data = await getTranscriptsCSV(1, filters, setCsvData, setIsDownloading, csvLinkEl, csvType, setNoData);
  }

  return (
    <>
        <div
            className='flex bg-[#6A1F70] items-center justify-center rounded  cursor-pointer py-1.5 px-3 gap-2'
            // style={{ background: requiredColorForPage.primaryColor ? requiredColorForPage.primaryColor : '#6A1F70' }}
            style={{
              background: requiredColorForPage.primaryColor ? requiredColorForPage.primaryColor : !isSelectedMerchant ? '#6A1F70' : '#FFFFFF',
              border: isSelectedMerchant && !requiredColorForPage.primaryColor ? '1px solid #6A1F70' : 'none',
              color: isSelectedMerchant && !requiredColorForPage.primaryColor ? '#6A1F70' : '#ffffff',
            }}
          >
            <img src={DownloadIcon} width="16" height="16" />
            <input type="button" value={label?label:"Download CSV"} onClick={() => {if(type === "feedback"){downloadFeedback()}else if(type === "transcripts"){downloadTranscripts()}else{downloadReport()}}}
              className="font-poppins font-normal text-base cursor-pointer disabled:opacity-50"
              disabled={isDownloading}
            />
            <CSVLink headers={headers} data={csvData} ref={csvLinkEl} filename={`${label}_${current_date}.csv`} className="absolute invisible">Download</CSVLink>
          </div>
          <div>
            {
              isDownloading &&
              <svg class={`animate-spin p-1 h-10 w-10 `}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                style={{ color: requiredColorForPage.primaryColor ? requiredColorForPage.primaryColor : '#6A1F70' }}
              >
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            }
          </div>
    </> 

  );
};

export default DownloadTableData;