import { useEffect, useState, useRef, useMemo} from 'react'
import Dropdown from 'react-dropdown';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import Select from 'react-select';
import _ from 'lodash';
import * as d3Base from 'd3';
// import * as sankey from 'd3-sankey-diagram';
import * as sankey from 'd3-sankey-awesome';
import toGetColorCode from 'hooks/useToGetColorCode';
import { getUserConfig, getTopicDescription, addToolTipDiv, getDescriptionForName } from 'utils/utils';
import ListItemCount from '../../../components/listItemCount/ListItemCount';
import NoResultFound from 'components/noResultFound/NoResultFound';


import { setFilters } from 'store/actions';
import { getTopics } from 'store/actions';
import Filters from "../../../components/filters";
import BubbleChart from 'components/charts/BubbleChart';
import './styles.css';
import { getTopicsFlowAnalysisVisualizationData } from 'store/actions';
import Rselect from './Components/RSelect';

var tinycolor = require("tinycolor2");

function ordinal_number(n) {
    let ord = 'th';

    if (n % 10 == 1 && n % 100 != 11) {
        ord = 'st';
    }
    else if (n % 10 == 2 && n % 100 != 12) {
        ord = 'nd';
    }
    else if (n % 10 == 3 && n % 100 != 13) {
        ord = 'rd';
    }

    return n + ord;
}

function transformOtherWithTopK(data, topK) {
    // return data;
    const newData = [];
    const sortedNodesByTopicCountMap = {};
    for (const _data of data) {
        const {
            topic,
            prev_topic,
            flow_index,
            topic_count,
            topic_color,
        } = _data;

        if (flow_index in sortedNodesByTopicCountMap) {
            sortedNodesByTopicCountMap[flow_index].push(_data);

        } else {
            sortedNodesByTopicCountMap[flow_index] = [_data];
        }
    }

   // for (const index in sortedNodesByTopicCountMap) {
       // sortedNodesByTopicCountMap[index] = [...sortedNodesByTopicCountMap[index].sort((a, b) => b.topic_count - a.topic_count)];
   // }

   for (const index in sortedNodesByTopicCountMap) {
   const topicCountMap = {};

   sortedNodesByTopicCountMap[index].forEach(item => {
        const { topic, topic_count } = item;
        if (topic in topicCountMap) {
            topicCountMap[topic] += topic_count;
        } else {
            topicCountMap[topic] = topic_count;
        }
    });

    sortedNodesByTopicCountMap[index].sort((a, b) => {
        // Compare by aggregated topic_count (descending)
        const topicCountA = topicCountMap[a.topic];
        const topicCountB = topicCountMap[b.topic];

        if (topicCountB !== topicCountA) {
            return topicCountB - topicCountA; // Sort by topic_count (descending)
        } else {
            // If topic_counts are the same, sort alphabetically by topic (ascending)
            const topicA = a.topic.toLowerCase();
            const topicB = b.topic.toLowerCase();
            if (topicA < topicB) {
                return -1;
            }
            if (topicA > topicB) {
                return 1;
            }
            return 0;
        }
    });
}
console.log("sortedNodesByTopicCountMap ---------- ", sortedNodesByTopicCountMap)
    let idx = 1;
    while (idx in sortedNodesByTopicCountMap) {
        const isSchedulingATopicAtThisIndex = sortedNodesByTopicCountMap[idx].findIndex((obj) => obj.topic == "Scheduling");
        let schedulingTopicAtThisIndexObj;
        let isSchedulingAdded = false;
        if (isSchedulingATopicAtThisIndex != -1) {
            isSchedulingAdded = true;
            schedulingTopicAtThisIndexObj = sortedNodesByTopicCountMap[idx][isSchedulingATopicAtThisIndex];
            sortedNodesByTopicCountMap[idx] = [sortedNodesByTopicCountMap[idx][isSchedulingATopicAtThisIndex], ...sortedNodesByTopicCountMap[idx].slice(0, isSchedulingATopicAtThisIndex), ...sortedNodesByTopicCountMap[idx].slice(isSchedulingATopicAtThisIndex + 1)];
        }
        const topicsInIndex = sortedNodesByTopicCountMap[idx].length;

        // CONDITION TO SKIP IF THE COUNT OF TOPICS IS LESS THAN THE TOP K itself
        if (topicsInIndex <= topK + (isSchedulingAdded ? 1 : 0)) {
            newData.push(...sortedNodesByTopicCountMap[idx]);
            idx += 1;
            continue;
        }

        const topicsClubbedInThisIndexToOthers = {};
        const newTopicsForReplacement = [];
        let topicCount = 0;
        let topicCountNumber = 0;
        let duplicateAdded = 0;
        for (let k = 0; k < topK; k++) {
            const {
                topic,
                topic_count,
                prev_topic,
                topic_color,
                topic_count_number
            } = sortedNodesByTopicCountMap[idx][k];
        }

        const topicCountMap = {};

        sortedNodesByTopicCountMap[idx].forEach(item => {
             const { topic, topic_count } = item;
             if (topic in topicCountMap) {
                 topicCountMap[topic] += topic_count;
             } else {
                 topicCountMap[topic] = topic_count;
             }
         });
     
        const uniqueTopics = Object.keys(topicCountMap).map(topic => ({
            topic,
            total_topic_count: topicCountMap[topic]
        }));
        
        uniqueTopics.sort((a, b) => b.total_topic_count - a.total_topic_count);
    
        const duplicate = [];
        const seenTopics = new Set();
    
        for (const topicObj of uniqueTopics) {
            if (!seenTopics.has(topicObj.topic)) {
                duplicate.push(topicObj.topic);
                seenTopics.add(topicObj.topic);
            }
            if (duplicate.length === topK) {
                break; // Stop after collecting the top two unique topics
            }
        }
    
    



        console.log("duplicate ---- already in --- ", duplicate)
        for (let k = topK + (isSchedulingAdded ? 1 : 0); k < topicsInIndex; k++) {
            const {
                topic,
                topic_count,
                prev_topic,
                topic_color,
                topic_count_number
            } = sortedNodesByTopicCountMap[idx][k];

            if (duplicate.includes(topic)) {
                console.log("duplicate ---- ", topic)
                duplicateAdded++;
                continue;
            }


            topicsClubbedInThisIndexToOthers[topic] = true;
            topicCount += Number(topic_count);
            topicCountNumber += Number(topic_count_number);
            newTopicsForReplacement.push({
                topic: 'Multiple',
                prev_topic,
                flow_index: idx,
                topic_count,
                topic_count_number,
                topic_color: topic_color || 'rgb(200,200,200)',
            });
        }


        // PICK TOP K + OTHERS to display on INDEX
        const topicsForThisIndex = [
            ...sortedNodesByTopicCountMap[idx].slice(0, (topK + (isSchedulingAdded ? 1 : 0) + duplicateAdded)),
            ...newTopicsForReplacement
        ];


        const clubbedNewTopicsForReplacement = [];
        for (const topicForThisIndex of topicsForThisIndex) {
            const {
                topic,
                prev_topic,
                topic_count,
                topic_count_number
            } = topicForThisIndex;

            const findIndex = clubbedNewTopicsForReplacement.findIndex((obj) => (obj.topic == topic && obj.prev_topic == prev_topic));
            if (findIndex == -1) {
                clubbedNewTopicsForReplacement.push(topicForThisIndex);
            } else {
                clubbedNewTopicsForReplacement[findIndex].topic_count += Number(topic_count);
                clubbedNewTopicsForReplacement[findIndex].topic_count_number += Number(topic_count_number);
            }
        }

        sortedNodesByTopicCountMap[idx] = clubbedNewTopicsForReplacement;

        // change all the prev topic occurrences in the next index
        if ((idx + 1) in sortedNodesByTopicCountMap) {
            for (const __data of sortedNodesByTopicCountMap[idx + 1]) {
                if (__data.prev_topic in topicsClubbedInThisIndexToOthers) {
                    __data.prev_topic = 'Multiple'
                }
            }
        }
        
        newData.push(...sortedNodesByTopicCountMap[idx]);

        idx += 1;
    }
    // Create a set to keep track of unique values
    const uniqueCombinations = new Set();

    // Use filter to remove duplicates based on the "topic" property
    const filteredArray = newData.filter(obj => {
        const key = `${obj.topic}-${obj.prev_topic}-${obj.flow_index}`;
        const isUnique = !uniqueCombinations.has(key);
        uniqueCombinations.add(key);
        return isUnique;
    });

    // return newData;
    return filteredArray;
    // return data;
}

function getSortedOrder(list) {
    // Extract values and counts from the given data
    let values = list;
    let counts = list['count'];


    // Combine values with their corresponding counts into an array of objects
    let combined = values.map((value, index) => {
        return { value: value, count: counts[index] };
    });

    // Sort the combined array of objects based on the 'count' property in descending order,
    // and then by 'value' property alphabetically in case of ties
    combined.sort((a, b) => {
        // Specify custom sorting logic
     //   if (a.value.includes('Callback Request') || a.value.includes('Callback Submit') || a.value.includes('Meeting Request') || a.value.includes('Meeting Submit')) {
    //      return -1; // Move 'get demo' and 'submitted' to the front
    //    } else if (a.value.includes('Callback Request') || a.value.includes('Callback Submit') || a.value.includes('Meeting Request') || a.value.includes('Meeting Submit')) {
    //      return 1; // Move 'get demo' and 'submitted' to the front
    //    } else {
          // Sort primarily by count in descending order
          if (b.count !== a.count) {
            return b.count - a.count;
          } else {
            // Sort alphabetically by value in case of ties on count
            return a.value.localeCompare(b.value);
          }
    //    }
      });

    // Extract the sorted values based on the sorted array of objects
    let sortedValues = combined.map(item => item.value);

    return sortedValues;

}

function transformFlowVisualizationToSankeyCharts(ogData, origins, origin_distribution, topK = 1, originValue, nodeToFocus, linkToFocus, merchantColors, total_not_engaged, total_conversations) {
    // nodes
    if (!ogData || ogData.length == 0) {
        return {
            nodes: [],
            links: [],
            groups: [],
            order: [],
        };
    }
    const data = transformOtherWithTopK(_.cloneDeep(ogData), topK);
    const nodes = [];
    // links
    const links = [];
    // groups
    const groups = [];
    // order
    const order = [];
    const inflowOutflowMap = {};
    const lookupByFlowIndex = {};
    const lookUpMetaByFlowIndex = {};
    const groupNodesByFlowIndex = {};
    const groupNodesByFlowIndexLinkages = {};
    const nodesSet = new Set();
    for (const _data of data) {
        const {
            topic,
            prev_topic,
            flow_index,
            topic_count,
            topic_count_number
        } = _data;

        const groupNodes = [];
        let topic_color = merchantColors[topic]
        //  SET LOOKUP
        const obj = {
            topic: prev_topic,
            count: topic_count,
            color: topic_color,
        }
        let check = false;
        if (flow_index in lookupByFlowIndex) {

            if (topic in lookupByFlowIndex[flow_index]) {
                lookupByFlowIndex[flow_index][topic].prevs.push({
                    ...obj,
                });
            } else {
                lookupByFlowIndex[flow_index][topic] = {
                    prevs: [{ ...obj }],
                    linkages: 0,
                    topic_count: 0,
                    topic_count_number: 0,
                };
            }
        } else {
            lookupByFlowIndex[flow_index] = {
                [topic]: {
                    prevs: [{
                        ...obj
                    }],
                    linkages: 0,
                    topic_count: 0,
                    topic_count_number: 0
                },
            };
            groupNodesByFlowIndex[flow_index] = [];
            groupNodesByFlowIndex[flow_index]['count'] = [];
            lookUpMetaByFlowIndex[flow_index] = {
                total_count: topic_count,
            };
        }
        lookupByFlowIndex[flow_index][topic].topic_count += Number(topic_count);
        lookupByFlowIndex[flow_index][topic].topic_count_number += (Number(topic_count_number) / 100);

        // NODES CREATE
        if (!nodesSet.has(`${flow_index}^${topic}`)) {
            const nodeID = `${flow_index}^${topic}`;
            let nodeBackgroundColor;
            let opacity = 0.4;
            let nodeFlow = false;

            nodeFlow = true;
            if (topic_color) {
            var bc = topic_color.match(/\d+/g);
            nodeBackgroundColor = `rgba(${bc[0]}, ${bc[1]}, ${bc[2]}, .2)`;
            }
            else {
                nodeBackgroundColor = 'rgb(169,206,127, .2)';
            }
            opacity = 0.4;

            nodeBackgroundColor = topic_color;
            
            var tmp_topic_count = 0;
            for (let i = 0; i < data.length; i++) {
                if (data[i].flow_index === flow_index && data[i].topic === topic) {
                    tmp_topic_count += data[i].topic_count;
                }
            }

            var tmp_topic_count_number = 0;
            for (let i = 0; i < data.length; i++) {
                if (data[i].flow_index === flow_index && data[i].topic === topic) {
                    tmp_topic_count_number += data[i].topic_count_number;
                }
            }

            var tooltipText = getNodeToolTipHTML(tmp_topic_count, topic);

            const node = {
                "bundle": null,
                "title": topic + " - " + (tmp_topic_count_number / 100) + ", " + tmp_topic_count.toFixed(2) + "%",
                "visibility": "visible",
                "def_pos": null,
                "id": nodeID,
                "style": "process",
                "direction": "r",
                dx: 100,
                "tooltipText":"",
                "backgroundColor": nodeBackgroundColor,
                "opacity": 1,
            };
            nodesSet.add(nodeID);
            if (!topic || topic <= 0) {
                continue;
            }
            nodes.push({ ...node });
            groupNodesByFlowIndex[flow_index].push(nodeID);
            if (nodeID.includes('Multiple')) {
                groupNodesByFlowIndex[flow_index]['count'].push(0);
            }
            else {
                groupNodesByFlowIndex[flow_index]['count'].push(tmp_topic_count.toFixed(3));
            }
        }
    }
    // LINKS
    const droppedNodeLinkages = {};
    for (const flowIndex in lookupByFlowIndex) {
        const group = {
            "type": "group",
            "title": `${ordinal_number(flowIndex)} Turn`,
            "bundle": null,
            "id": flowIndex.toString(),
            "nodes": groupNodesByFlowIndex[flowIndex],
            "def_pos": null
        };
        groups.push({ ...group });

        if (flowIndex == 1) {
            const topicsInFlowIndex = lookupByFlowIndex[flowIndex];
            var sortedOrder = getSortedOrder(groupNodesByFlowIndex[flowIndex])
            order.push([
                [...sortedOrder], []
            ]);
            continue;
        }
        const topicsInFlowIndex = lookupByFlowIndex[flowIndex];
        const topicsInPrevFlowIndex = lookupByFlowIndex[flowIndex - 1];
        
        for (const topic in topicsInFlowIndex) {
            const topicData = topicsInFlowIndex[topic];
            for (const prev of topicData.prevs) {
                if (prev.topic in topicsInPrevFlowIndex) {
                    let linkageValue;
                    let totalLinkageInValue = 0;
                    let totalLinkagePrevValue = 0;

                    for (const topic_inflow of topicData.prevs) {
                       // console.log(topic_inflow)
                        var tmp = Number(topic_inflow.count.toFixed(2))
                        totalLinkageInValue += tmp;
                    }

                    // if (prev.topic ===  topic) {
                    //     continue;
                    // }
                    // if (topicsInPrevFlowIndex[prev.topic].linkages + prev.count > topicsInPrevFlowIndex[prev.topic].topic_count) {
                    // linkageValue = topicsInPrevFlowIndex[prev.topic].topic_count - prev.count;
                    // linkageValue = topicsInPrevFlowIndex[prev.topic].topic_count - topicsInPrevFlowIndex[prev.topic].linkages;
                    // topicsInPrevFlowIndex[prev.topic].linkages += topicsInPrevFlowIndex[prev.topic].topic_count - prev.count;
                    // } else {
                    // topicsInPrevFlowIndex[prev.topic].linkages += prev.count;
                    linkageValue = prev.count.toFixed(2);
                    // topicsInPrevFlowIndex[prev.topic].linkages += linkageValue;
                    // }

                    const topic_prevflow = topicsInPrevFlowIndex[prev.topic];
                    totalLinkagePrevValue = (topic_prevflow.topic_count).toFixed(2);

                    if (flowIndex == 5 && topic == "Scheduling") {
                      //  console.log("testing....", linkageValue, prev.topic);
                        // linkageValue = 2.86
                    }

                    const sourceOfLinkID = `${flowIndex - 1}^${prev.topic}`
                    const targetOfLinkID = `${flowIndex}^${topic}`;

                    let linkColor;
                    let prevColor;
                    let opacity = 0.5;

                    if (linkToFocus) {
                        opacity = 0.1;
                    }

                    let nodeFlow = false;
                        linkColor = topic == "Scheduling" ? "rgb(190,219,160)" : "rgb(219,227,228)";
                    

                            var prev_node_color = 'rgb(169,206,127)';
                            for (const node of nodes) {
                                if (node.id == sourceOfLinkID) {
                                    prev_node_color = node.backgroundColor;
                                    break;
                                }
                            }
                            linkColor = topic == "Scheduling" ? "rgb(190,219,160)" : prev.color;
                            prevColor = prev_node_color;

                    var totallinkage_from = (linkageValue/totalLinkagePrevValue).toFixed(4);
                    var totallinkage_to = (linkageValue/totalLinkageInValue).toFixed(4);

                    var tooltipText = getToolTipHTML(totallinkage_from, totallinkage_to, prevColor, linkColor, linkageValue, Number(totalLinkageInValue).toFixed(2));

                    const link = {
                        // "color": prev.color,
                        // "color": topic == "Scheduling" ?  "rgb(190,219,160)" : "rgb(219,227,228, 0.2)",
                        color: linkColor,
                        "prevColor": prevColor,
                        'tooltipText':tooltipText,
                        "source": sourceOfLinkID,
                        "value": linkageValue,
                        "type": `Turn ${flowIndex}`,
                        "target": targetOfLinkID,
                        "opacity": opacity,
                        "time": "*",
                        "title": `Turn ${flowIndex - 1} ➡️ Turn ${flowIndex}`,
                        dx: 100
                    };
                    // !(link.source in droppedNodeLinkages) TODO:  on all dropoff
                    if (linkageValue > 0) {
                        links.push({ ...link });
                        topicsInPrevFlowIndex[prev.topic].linkages += parseFloat(linkageValue);
                        if (`${flowIndex - 1}^${prev.topic}` in inflowOutflowMap) {                           
                            inflowOutflowMap[`${flowIndex - 1}^${prev.topic}`].out = parseFloat(inflowOutflowMap[`${flowIndex - 1}^${prev.topic}`].out) + parseFloat(linkageValue)
                        } else {
                            inflowOutflowMap[`${flowIndex - 1}^${prev.topic}`] = {
                                in: 0,
                                out: linkageValue,
                            };
                        }

                        if (`${flowIndex}^${topic}` in inflowOutflowMap) {
                            inflowOutflowMap[`${flowIndex}^${topic}`].in = parseFloat(inflowOutflowMap[`${flowIndex}^${topic}`].in) + parseFloat(linkageValue)
                        } else {
                            inflowOutflowMap[`${flowIndex}^${topic}`] = {
                                in: linkageValue,
                                out: 0,
                            };
                        }
                    } else {
                        // remove this node as well
                        // droppedNodeLinkages[link.target] = true;
                    }
                }
            }
        }
        // const dropoffByFlowIndex = {};
        let isDropoffLinkNeededForThisFlowIndex = false;
        for (const topic in topicsInPrevFlowIndex) {
            const topicData = topicsInPrevFlowIndex[topic];
            const {
                linkages,
                topic_count,
            } = topicData;

            const dropoff = topic_count - linkages;
            if (dropoff <= 0 || topic == "Scheduling") {
                continue;
            }

            const dropoffLink = {
                "color": "#FB6962",
                "source": `${flowIndex - 1}^${topic}`,
                "value": dropoff,
                "title": `Turn ${flowIndex - 1} ➡️ Turn ${flowIndex}`,
                "target": `${flowIndex}^dropoff`,
                "opacity": 1.0,
                "time": "*",
                "title": `${topic} ➡️ Dropoff`
            };
            // if (dropoffLink.source in droppedNodeLinkages) {
            //     continue;
            // }
            // isDropoffLinkNeededForThisFlowIndex = true;
            // links.push(dropoffLink);
        }

        if (false) {
            const node = {
                "bundle": null,
                "title": 'dropoff',
                "visibility": "visible",
                "def_pos": null,
                "id": `${flowIndex}^dropoff`,
                "style": "process",
                "direction": "r",
                "backgroundColor": 'rgb(249,249,249)',
                // "dropoff": 100
            };
            nodes.push(node);
            order.push([
                [...groupNodesByFlowIndex[flowIndex], `${flowIndex}^dropoff`],
            ]);
        } else {
            var sortedOrder = getSortedOrder(groupNodesByFlowIndex[flowIndex])
            order.push([
                [...sortedOrder],
            ]);
        }
    }


    // SORT ON THE BASIS OF PERCENTAGE
    for (const flowIndex in lookupByFlowIndex) {
        const linkageMapByIndex = {};
        const indexNodes = lookupByFlowIndex[flowIndex];
        for (const node in indexNodes) {
            const nodeDetails = indexNodes[node];
            const {
                linkages,
            } = nodeDetails;

            const nodeId = `${flowIndex}^${node}`;
            linkageMapByIndex[nodeId] = linkages;
        }

        linkageMapByIndex[`${flowIndex}^Scheduling`] = 1000;
        linkageMapByIndex[`${flowIndex}^Multiple`] = -1000;

/*
        const orderByIndex = order[flowIndex - 1][0];
        order[flowIndex - 1][0] = orderByIndex.sort((a, b) => {
            return linkageMapByIndex[b] - linkageMapByIndex[a]
        });
        */
    }


    // JUST ADD UTM SOURCE

    // map origin map
    if (Object.keys(origin_distribution).length > 0) {
        const origins = {};
        const originCountMap = {};
        let totalCount = 0;

        for (const topic in origin_distribution) {
            let total = 0;
            for (const origin in origin_distribution[topic]) {
                origins[origin] = true;
                const contribution = parseFloat(origin_distribution[topic][origin]);
                total += contribution;
                totalCount += contribution;
                if (origin in originCountMap) {
                    originCountMap[origin] += contribution;
                } else {
                    originCountMap[origin] = contribution;
                }
            }

            for (const origin in origin_distribution[topic]) {
                const contributionPercentage = (parseFloat(origin_distribution[topic][origin]) / total) * 100;
                origin_distribution[topic][origin] = contributionPercentage;
            }
        }

        // create nodes
        const originNodes = [];
        for (const origin in origins) {
            const nodeID = `0^${origin}`;
            let nodeBackgroundColor;
            let opacity = 0.4;
                nodeBackgroundColor = 'rgb(76,167,114)';

            var percentage = ((originCountMap[origin] / totalCount) * 100).toFixed(2);
            const node = {
                "bundle": null,
                "title": origin + " - " + originCountMap[origin] + ', ' + percentage + "%",
                "visibility": "visible",
                "def_pos": null,
                "id": nodeID,
                "style": "process",
                "direction": "r",
                "population": ((originCountMap[origin] / totalCount) * 100),
                "backgroundColor": nodeBackgroundColor,
                "tooltipText":"",
                "opacity": opacity,
            };
            nodes.unshift(node);
            originNodes.push(nodeID);
        }

        const originGroup = {
            "type": "group",
            "title": originValue,
            "bundle": null,
            "id": "0",
            "nodes": originNodes,
            "def_pos": null
        };

        groups.unshift(originGroup);

        for (const topic in lookupByFlowIndex[1]) {
            const topic_count = lookupByFlowIndex[1][topic].topic_count;
            const topic_color = lookupByFlowIndex[1][topic].prevs[0].color;
            const linkages = lookupByFlowIndex[1][topic].linkages;
            const originsForTopic = origin_distribution[topic];
            for (const originForTopic in originsForTopic) {
                const origin_percentage = originsForTopic[originForTopic];
                let totalLinkageInValue = 0;
                totalLinkageInValue = (lookupByFlowIndex[1][topic].topic_count).toFixed(2);

                const sourceOfLinkID = `0^${originForTopic}`;
                const targetOfLinkID = `1^${topic}`;


                let linkColor;
                let prevcolor = lightenRGBColor('rgb(76,167,114)', 0.3);
                    linkColor = topic == "Scheduling" ? "rgb(190,219,160)" : lightenRGBColor(topic_color, 0.3);;
                let opacity = 0.5;

                if (linkToFocus) {
                    opacity = 0.1;
                }



                var linkageValue = (((topic_count) * (origin_percentage)) / 100)

                var totallinkage_from = (linkageValue/100).toFixed(2);
                var totallinkage_to = (linkageValue/totalLinkageInValue).toFixed(2);

                var tooltipText = getToolTipHTML(totallinkage_from, totallinkage_to, prevcolor, linkColor, Number(linkageValue).toFixed(2), totalLinkageInValue);

                const link = {
                    "color": linkColor,
                    "prevColor": prevcolor,
                    "tooltipText":tooltipText,
                    "source": sourceOfLinkID,
                    "value": linkageValue,
                    "type": "P",
                    "target": targetOfLinkID,
                    "opacity": opacity,
                    "title": "P",
                    dx: 100,
                };
                links.push(link);
            }
            // Check others
            if (topic == "Multiple") {
                const linkages = lookupByFlowIndex[1]["Multiple"].linkages;
                const topic_count = lookupByFlowIndex[1]["Multiple"].topic_count;
                const topic_count_number = lookupByFlowIndex[1]["Multiple"].topic_count_number;
                const prevColor = lookupByFlowIndex[1]["Multiple"].prevs[0].color;
                const othersDistribution = {};
                let totalByTopic = 0;
                for (const _topic in origin_distribution) {
                    if (!(_topic in lookupByFlowIndex[1])) {
                        const distributionInTopic = origin_distribution[_topic];
                        for (const distribution in distributionInTopic) {
                            if (distribution in othersDistribution) {
                                othersDistribution[distribution] += distributionInTopic[distribution];
                            } else {
                                othersDistribution[distribution] = distributionInTopic[distribution];
                            }
                            totalByTopic += distributionInTopic[distribution]
                        }
                    }
                }


                for (const distribution in othersDistribution) {
                    var linkageValue = (linkages) * (othersDistribution[distribution] * 100 / totalByTopic)
                    var totallinkage_from = (topic_count/100).toFixed(2);
                    var totallinkage_to = 1;
                    var tooltipText = getToolTipHTML(totallinkage_from, totallinkage_to, "rgb(76,167,114)", prevColor, Number(topic_count).toFixed(2), totallinkage_to);
                    let opacity = 0.5;

                    if (linkToFocus) {
                        opacity = 0.1;
                    }

                    const link = {
                        "color": prevColor,
                        "prevColor": "rgb(76,167,114)",
                        "source": `0^${distribution}`,
                        "tooltipText":tooltipText,
                        "value": (linkageValue / 100),
                        "type": "P",
                        "target": `1^Multiple`,
                        "opacity": opacity,
                        "title": "P",
                        dx: 100,
                    };
                    links.push(link);
                }

            }

        }
        order.unshift([[...originNodes.sort((a, b) => origins[b.split('^')[1]] - origins[a.split('^')[1]])], []]);
    }


    let totalPopulation = 0;
    const topicsInFlowIndex = lookupByFlowIndex[1];
    for (const _data of data) {
        const {
            topic,
            flow_index,
            topic_count,
        } = _data;
        if (flow_index == 1) {
            totalPopulation += Number(topic_count);
            const nodeId = `${flow_index}^${topic}`;
            if (nodeId in inflowOutflowMap) {
                inflowOutflowMap[nodeId].total = topic_count;
            }
        }
    }

    // ADD DROPOFFs to the nodes
    const metaByFlowIndex = {};
    for (const node of nodes) {
        const nodeId = node.id;
        if (nodeId in inflowOutflowMap) {
            const flowIndex = parseInt(nodeId.split('^')[0]);
            const topicName = nodeId.split('^')[1];
            if (flowIndex == 0 || topicName === "Scheduling") {
                continue;
            }
            var topic_count_number = lookupByFlowIndex[flowIndex][topicName].topic_count_number;

            if (flowIndex == 1) {
                // console.log("121212121121212", lookupByFlowIndex[1], topicName)
                const topic = nodeId.split('^')[1];
                inflowOutflowMap[nodeId]['in'] = lookupByFlowIndex[1][topic].topic_count;
            }
            const {
                in: inflow,
                out: outflow,
                total,
            } = inflowOutflowMap[nodeId];

            if (inflow > outflow) {
                const dropoff = ((inflow - outflow) * 100 / inflow).toFixed(1);
                if (dropoff > 0.0) {
                    node.population = inflow;
                    node.dropoff_count = inflow - outflow;
                    node.dropoff = dropoff;
                    node.dropoff_color = nodeId.split('^')[1] == "Scheduling" ? 'rgb(76,167,114)' : 'rgb(227,69,64)';
                    node.dropoff_contribution = (dropoff * inflow) / 100;
                    var tooltips_dropoff = getNodeToolTipHTML(inflow - outflow, "Dropoff");
                    node.tooltipTextDropoff = tooltips_dropoff;
                }
            }
            node.population = ((total || inflow) / totalPopulation) * 100
            if (flowIndex in metaByFlowIndex) {
                metaByFlowIndex[flowIndex].total_population += node.population;
                metaByFlowIndex[flowIndex].total_dropoff += (node.dropoff_count) || 0;
                metaByFlowIndex[flowIndex].total_conversation_drop += (topic_count_number) || 0;
            } else {
                metaByFlowIndex[flowIndex] = {
                    total_population: node.population,
                    total_dropoff: (node.dropoff_count) || 0,
                    total_conversation_drop:(topic_count_number) || 0,
                };
            }
        } else {
            // place to add drop off for first index
            const flowIndex = parseInt(nodeId.split('^')[0]);
            const topic = nodeId.split('^')[1];

            if (flowIndex == 0) {
                continue;
            }

            const inflow = lookupByFlowIndex[flowIndex][topic].topic_count;
            node.population = inflow
            node.dropoff_color = nodeId.split('^')[1] == "Scheduling" ? 'rgb(76,167,114)' : 'rgb(227,69,64)';
            node.dropoff_contribution = inflow;
            node.dropoff_count = inflow;

            if (flowIndex in metaByFlowIndex) {
                metaByFlowIndex[flowIndex].total_population += node.population;
                metaByFlowIndex[flowIndex].total_dropoff += (node.dropoff_count) || 0;
            } else {
                metaByFlowIndex[flowIndex] = {
                    total_population: node.population,
                    total_dropoff: (node.dropoff_count) || 0,
                };
            }
        }
    }
    if (1 in metaByFlowIndex) {
        metaByFlowIndex[0] = {
            total_population: metaByFlowIndex[1].total_population,
            total_dropoff: 0,
        };
    }

    var engaged_user = 0;
    for (let i = 0; i < groups.length; i++) {
        if (i == 0) {
            var avg_not_engaged = 0;
            for (const key in total_not_engaged) {
                if (total_not_engaged.hasOwnProperty(key)) {
                    avg_not_engaged += total_not_engaged[key].not_engaged;
                }
            }
            const dropoffToDisplay = ((avg_not_engaged / total_conversations) * 100).toFixed(1);
            engaged_user = total_conversations - avg_not_engaged;
            groups[i].title += `$$  User: ${total_conversations - avg_not_engaged}, ${(((total_conversations - avg_not_engaged) / total_conversations)*100).toFixed(1)}%`;
            groups[i].title += `$$  Drop-offs: ${avg_not_engaged}, ${dropoffToDisplay >= 0 ? dropoffToDisplay : 0}%`;
            continue;
        }
        let schedulingContribution = 0;
        const groupId = groups[i].id;
        if ('Scheduling' in lookupByFlowIndex[groupId]) {
            schedulingContribution = lookupByFlowIndex[groupId]['Scheduling'].topic_count;
        }
        const dropoffToDisplay = Math.abs(((metaByFlowIndex[groupId].total_dropoff - schedulingContribution))).toFixed(1);
        let dropoff_percentage = ((dropoffToDisplay / metaByFlowIndex[groupId].total_population)*100).toFixed(1)
        const dropoff_count = Math.round((engaged_user/100)*dropoff_percentage);
        const total_dropoff_percentage = ((dropoff_count/total_conversations)*100).toFixed(1);
        groups[i].title += `$$  User: ${engaged_user}`;
        groups[i].title += `$$  Drop-off: ${dropoff_count}, ${total_dropoff_percentage >= 0 ? total_dropoff_percentage : 0}%`;
        engaged_user = engaged_user - dropoff_count;
    }
    order.unshift([["-1^GOOGLE"], "-1^FACEBOOK"], [])

    if (linkToFocus !== null) {
        highlight_links(links, linkToFocus)
        if (linkToFocus.source.incoming.length > 0) {
            highlight_source_links(links, linkToFocus.source.incoming)
        }
        if (linkToFocus.target.outgoing.length > 0) {
            highlight_target_links(links, linkToFocus.target.outgoing)
        }
    }

    return {
        nodes,
        links: links,
        groups,
        order,
    };
}

function highlight_links(links, linkToFocus) {
    if (linkToFocus !== null) {
        var tmp_links = [];
        links.forEach(async (__links) => {
            if (linkToFocus.source.id === __links.source && linkToFocus.target.id === __links.target) {
                __links.opacity = 1;
                return
            }
            tmp_links.push(__links)
        })
        return links;
    }
    else {
        return links;
    }
}

function highlight_target_links(links, linkToFocus) {
    links.forEach((__links) => {
        linkToFocus.forEach(async(__linksfocus) => {
            if (__linksfocus.source.id === __links.source && __linksfocus.target.id === __links.target) {
                __links.opacity = 1;
                if (__linksfocus.target.outgoing.length > 0) {
                    await highlight_target_links(links, __linksfocus.target.outgoing)
                }
            }
        })
    })
}

function highlight_source_links(links, linkToFocus) {
    links.forEach((__links) => {
        linkToFocus.forEach(async(__linksfocus) => {
            if (__linksfocus.source.id === __links.source && __linksfocus.target.id === __links.target) {
                __links.opacity = 1;
                if (__linksfocus.source.incoming.length > 0) {
                    await highlight_source_links(links, __linksfocus.source.incoming)
                }
            }
        })
    })
}


function getToolTipHTML(from,to, prev_color, to_color, from_value, to_value) {
    let html = "<div class='flex gap-4 justify-center items-center'><div class='flex gap-4 items-center'>"+from_value+"% <svg xmlns='http://www.w3.org/2000/svg' width='20px' height='20px' viewBox='0 0 48 48' fill='none'><path d='M24 36C30.6274 36 36 30.6274 36 24C36 17.3725 30.6274 12 24 12C17.3726 12 12 17.3725 12 24C12 30.6274 17.3726 36 24 36Z' fill='"+prev_color+"'/></svg>"
    html += "<div>" + (from * 100).toFixed()+"%</div></div>";
    html += "<div><svg xmlns='http://www.w3.org/2000/svg' width='6' height='10' viewBox='0 0 6 10' fill='none'><path d='M0.262451 10L5.26245 5L0.262451 0V10Z' fill='#1B1B1D'/></svg></div>"
    html += "<div class='flex gap-2 items-center'><svg xmlns='http://www.w3.org/2000/svg' width='20px' height='20px' viewBox='0 0 48 48' fill='none'><path d='M24 36C30.6274 36 36 30.6274 36 24C36 17.3725 30.6274 12 24 12C17.3726 12 12 17.3725 12 24C12 30.6274 17.3726 36 24 36Z' fill='"+to_color+"'/></svg>"
    html += "<div>" + (to * 100).toFixed()+"%</div></div>";
    html += "</div>";

    return html;
}

function getNodeToolTipHTML(value, title) {
    let html = "<div class='text-center center'>"
    html += "<div class='p-2'>"+title+"</div>";
    html += "<div>"+value.toFixed(2)+"%</div>";
    html += "</div>";

    return html;
}


function lightenRGBColor(color, percent) {
    if (color === "" || color === null) {
        return
    }
    // Extract the RGB values from the color string
    const regex = /^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/;
    const match = color.match(regex);

    if (!match) {
        throw new Error("Invalid RGB color format");
    }

    let [, r, g, b] = match.map(Number);

    // Calculate the new lighter color
    r = Math.floor(r * (1 + percent));
    g = Math.floor(g * (1 + percent));
    b = Math.floor(b * (1 + percent));

    // Ensure that the values are within the valid range (0-255)
    r = r > 255 ? 255 : r;
    g = g > 255 ? 255 : g;
    b = b > 255 ? 255 : b;

    // Return the new color in rgb() format
    return `rgb(${r}, ${g}, ${b})`;
}

let merchantColors = [];
export default function TopicFlow() {
    const [landingPage, setLandingPage] = useState([]);
    const [filterChange, setFilterChange] = useState(false);
    const [defaultOrigin, setDefaultOrigin] = useState(0);
    const [resetFilters, setResetFilters] = useState(false);
    const dispatch = useDispatch();
    const conversationEngagedSlice = useSelector((state) => state.chatRating);
    const filters = useSelector((state) => state.filters);
    const sankeyDiagramDiv = useRef(null);
    const [sankeyLoading, setSankeyLoading] = useState(0);
    const [utmSelected, setUtmSelected] = useState([]);
    const topicFlowSlice = useSelector((state) => state.topicFlow);
    const topicFlowOriginVisualisationData = topicFlowSlice.topicFlowData.origins;
    const topicDataLoading = topicFlowSlice.loading;
    const topicFlowOriginDistributionVisualisationData = topicFlowSlice.topicFlowData.origin_distribution;
    const topicFlowVisualizationData = topicFlowSlice.topicFlowData.data;
    const total = topicFlowSlice.topicFlowData.total_conversations ? topicFlowSlice.topicFlowData.total_conversations : 0;
    const total_not_engaged = topicFlowSlice.topicFlowData.total_conversations_notengaged ? topicFlowSlice.topicFlowData.total_conversations_notengaged : 0;
    // const [isSankeyDiagramUpdated, setIsSankeyDiagramUpdated] = useState(false);
    const [originOptions, setOriginOptions] = useState([
        { id: '1', value: 'utm_campaign', label: 'UTM Campaign' },
        { id: '2', value: 'device_type', label: 'Device Info' },
        { id: '3', value: 'utm_medium', label: 'UTM Medium' },
        { id: '4', value: 'utm_source', label: 'UTM Source' }
    ]);
    const [selectTopK, setSelectTopK] = useState({value: 10});


    const [nodeToFocus, setNodeToFocus] = useState(null);
    const [linkToFocus, setLinkToFocus] = useState(null);

    const selectedMerchant = useSelector((state) => state.selectedValue);
    const selectedTimezone = useSelector((state) => state.selectedTimezone);

    const customerConfig = useSelector((state) => state.customerConfig);

    const primaryColor = customerConfig.configuration && customerConfig.configuration['primary_color'];
    const secondaryColor = toGetColorCode(primaryColor, 3, 'white');
    const tertiaryColor = toGetColorCode(primaryColor, 5, 'white');
    const seventhColor = toGetColorCode(primaryColor, 7, 'white');
    const tenthColorShade = toGetColorCode(primaryColor, 9, 'white');
    const merchantColors = useMemo(() => getMerchantColors(topicFlowVisualizationData, primaryColor), [topicFlowOriginDistributionVisualisationData, primaryColor]);

    const [requiredColorForPage, setRequiredColorForPage] = useState({
        primaryColor: primaryColor,
        secondaryColor: secondaryColor,
        tertiaryColor: tertiaryColor,
        seventhColor: seventhColor,
        tenthColorShade: tenthColorShade
    })

    const isSelectedMerchant = selectedMerchant === 'All' || selectedMerchant === '' || selectedMerchant === null ? false : true


    function toggleNodeToFocus(node) {
        setLinkToFocus(null);
        setNodeToFocus((previousNodeToFocus) => {
            if (!previousNodeToFocus || node === null) {
                return node;
            } else if (previousNodeToFocus && previousNodeToFocus.id == node.id) {
                // its the click on same node
                console.log("yes clicked on same node");
                return null;
            } else if (previousNodeToFocus && previousNodeToFocus.id != node.id) {
                // its the click on different node
                return node;
            } else {
                console.log("unknown case");
                return node;
            }
        });
    }      

    function toggleLinkToFocus(link) {
        setNodeToFocus(null);
        setLinkToFocus((previousLinkToFocus) => {
            if (!previousLinkToFocus || previousLinkToFocus.source === null) {
                return link;
            } else if (previousLinkToFocus && ((previousLinkToFocus.source.id == link.source.id) && (previousLinkToFocus.target.id == link.target.id))) {
                // its the click on same link
                console.log("yes clicked on same node");
                return null;
            } else if (previousLinkToFocus && !((previousLinkToFocus.source.id == link.source.id) && (previousLinkToFocus.target.id == link.target.id))) {
                // its the click on different link
                return link;
            } else {
                console.log("unknown case");
                return link;
            }
        });
    }

    const d3 = {
        ...d3Base,
        sankey,
    }

    const handleUtmSourceSelections = (e) => {
        setUtmSelected(e);
    }

    useEffect(() => {
        setSankeyLoading(0)
        addToolTipDiv()
        const clonedData = _.cloneDeep(topicFlowVisualizationData)
        const d3SankeyChartsLayoutData = transformFlowVisualizationToSankeyCharts(clonedData, topicFlowOriginVisualisationData, topicFlowOriginDistributionVisualisationData, parseInt(selectTopK.value), filters.origin, nodeToFocus, linkToFocus, merchantColors,total_not_engaged,total);
        const groupCount = d3SankeyChartsLayoutData.groups.length;
        const layout = d3.sankey.sankey().extent([
            [0, 0],
            [groupCount * 450, 1000] // controls for spacing of the groups/sankey
        ]);

        const diagram = d3.sankey.sankeyDiagram().linkTitle(
            d3.sankey.sankeyLinkTitle(
                function (d) {
                    return d.title;
                },
                function (d) {
                    return d.title;
                },
                d3.format(".3s")
            )
        ).linkColor(function (d) {
            return d.color;
        }).prevColor(function (d) {
            return d.prevColor;
        }).opacity(function (d) {
            return d.opacity;
        });

        // LISTEN FOR D3 DISPATCH ( NODE CLICK )
        diagram.on('selectNode', (node) => {
            console.log("CLICK ON NODE CAPTURED:", node, nodeToFocus);
          //  if (node === null) {return}
            toggleNodeToFocus(node, nodeToFocus);
        });


        diagram.on('selectLink', (link) => {
            console.log("got the select evnet on link")
           // if (node === null) {return}
            setNodeToFocus(null);
            toggleLinkToFocus(link, linkToFocus);
        });


        layout.ordering([...d3SankeyChartsLayoutData.order]);
        d3.select(sankeyDiagramDiv.current)
            .datum(layout({ ...d3SankeyChartsLayoutData }))
            .call(diagram.groups([...d3SankeyChartsLayoutData.groups]))
            .call(function(){
                setSankeyLoading(sankeyLoading+1)
            });
            

        modifyAllPaths(d3);
        addTooltip();
        addTopicDescrption();
    }, [sankeyDiagramDiv, topicFlowVisualizationData, utmSelected, selectTopK, nodeToFocus, linkToFocus]);

    function getMerchantColors(topics, primary_color) {
        if (topics === undefined || Object.keys(topics).length === 0) return;
        let color = (primary_color !== '' && primary_color !== null && primary_color !== undefined)?tinycolor(primary_color):tinycolor("#6a2370");
        // Arrays to store lighter and darker colors
        let analogousColors = [];
        let complementaryColors = [];
        let splitcomplement = [];
        let tetradColors = [];
        
        // Generating analogous colors
        let analogousColor = color.analogous(10);
        analogousColor.map(t => {var col = t.toRgbString(); analogousColors.push(col)}); 


        // Generating splitcomplement colors
        let splitcomplementColor = color.splitcomplement(10);
        splitcomplementColor.map(t => {var col = t.toRgbString(); splitcomplement.push(col)}); 

        // Generating splitcomplement colors
        let tetradColor = color.tetrad(10);
        tetradColor.map(t => {var col = t.toRgbString(); tetradColors.push(col)}); 

        let merchantcolors = [...analogousColors, ...splitcomplement, ...tetradColors]
        let merchants = []
        for (const topic in topics) {
            if (topics.hasOwnProperty(topic) && !merchants.includes(topics[topic]['topic'])) {
              // Access each property using 'topic' as the key
              let random = Math.floor(Math.random() * merchantcolors.length);
              merchants[topics[topic]['topic']] = merchantcolors[random]
            }
        }
        let random = Math.floor(Math.random() * merchantcolors.length);
        merchants['Multiple'] = merchantcolors[random]

        return merchants;

    }

    async function modifyAllPaths(d3) {
        // Select all paths in your SVG
        let svg_element = document.getElementById('sankey');
        if (!svg_element) {
            return
        }

        var paths = d3.selectAll("path");

        // Iterate through each path
        paths.each(async function() {
        var path = d3.select(this);
        

        var prevColor = path.attr('prevColor');
        var styleAttr = path.attr('style');
        var class_name = path.attr('class');

        if (class_name === 'dropoff2') {
            return;
        }
        var currentFillColor = '';
        var currentOpacity = '';
        if (styleAttr) {
            currentFillColor = path.attr('style').match(/fill:\s*([^;]*)/)[1];
            currentOpacity = path.attr('style').match(/opacity:\s*([^;]*)/)[1];
        }

        var random = Math.floor(Math.random() * 999999);

        // Create a new linear gradient element
        let linearGradient = document.createElementNS("http://www.w3.org/2000/svg", "linearGradient");
        linearGradient.setAttribute("id", "grad_"+random); // Set the gradient ID

        // Define the gradient attributes (start and end points)
        linearGradient.setAttribute("x1", "25%");
        linearGradient.setAttribute("y1", "50%");
        linearGradient.setAttribute("x2", "100%");
        linearGradient.setAttribute("y2", "50%");

        // Define the gradient stops
        const stop1 = document.createElementNS("http://www.w3.org/2000/svg", "stop");
        stop1.setAttribute("offset", "0%");
        stop1.setAttribute("style", "stop-color:"+prevColor+";stop-opacity:0.7");
        linearGradient.appendChild(stop1);

        const stop2 = document.createElementNS("http://www.w3.org/2000/svg", "stop");
        stop2.setAttribute("offset", "50%");
        stop2.setAttribute("style", "stop-color:"+currentFillColor+";stop-opacity:1");
        linearGradient.appendChild(stop2);
        
        path.attr('style', 'fill: url(#grad_'+random+');opacity: '+currentOpacity); // Change to red
        
        // Apply the modified path back to the SVG element
        svg_element.appendChild(linearGradient);
        }

        );

    }

    function addTopicDescrption() {
        const svg_element = document.getElementById('sankey');
        if (!svg_element) {
            return
        }

        // Get all groups with class "link"
        const nodeText = svg_element.querySelectorAll('g.node text.node-value');
        
        const tooltip = document.getElementsByClassName('tooltip_topics')[0];
        nodeText.forEach(node => {
            var tspan = document.createElementNS("http://www.w3.org/2000/svg", "tspan");
            var tspan2 = document.createElementNS("http://www.w3.org/2000/svg", "tspan");
            // Add class to the <tspan> element
            tspan.setAttribute("class", "node_description");
            tspan2.setAttribute("class", "title_count");
            var nodetext = node.textContent;
            var extracted_detail = nodetext.split('-');

            // Add some text content to the <tspan>
            tspan.innerHTML = " &#9432;";
            if (extracted_detail[1] !== undefined) {
                tspan2.innerHTML = ' - ' + extracted_detail[1];
            }

            tspan.addEventListener('mouseover', async (event) => {
                const extractedString = nodetext.split('-')[0].trim();
                const desc = await getDescriptionForName(extractedString);
                tooltip.style.display = "none";
                if (desc) {
                    tooltip.textContent = desc;
                    tooltip.style.display = "block";
                    tooltip.style.left = (event.clientX + 5) + "px";
                    tooltip.style.top = (event.clientY + 5) + "px";      
                }      
            });
        
            tspan.addEventListener('mouseout', () => {
                tooltip.style.display = "none";
            });
            
            node.textContent = extracted_detail[0];
            node.appendChild(tspan2);
            node.appendChild(tspan);
        });
    }

    function addTooltip() {
        const svg_element = document.getElementById('sankey');
        if (!svg_element) {
            return
        }

        // Get all groups with class "link"
        const linkGroups = svg_element.querySelectorAll('g.link, g.node rect.dropoff');
        const foreignObject = document.getElementsByClassName("tooltip-text")[0];
        const div = document.getElementsByClassName("tooltip_text")[0];
        div.style.padding = "10px"; // Adjust padding

        foreignObject.appendChild(div);

        function updateTooltipPosition(x, y) {
            foreignObject.style.top = (y + 5) + "px";
            foreignObject.style.left = (x + 5) + "px";
        }

        linkGroups.forEach(link => {
            let title = link.querySelector('title');

            if (title === null) {
                title = link.nextElementSibling;
            }
            let titleText = ''
            if (title !== null) {
                titleText = title.textContent;
                // Hide the default title tooltip
                title.textContent = '';
            }


            link.addEventListener('mouseover', (event) => {
                var texts = titleText.split("\n\r")
                div.textContent = '';
                texts.forEach((text) => {
                    div.innerHTML += '<div>'+text+'</div>'
                })
                foreignObject.style.display = 'block';
                updateTooltipPosition(event.clientX, event.clientY);
            });

            link.addEventListener('mousemove', (event) => {
                updateTooltipPosition(event.clientX, event.clientY);
            });

            link.addEventListener('mouseout', () => {
                foreignObject.style.display = 'none';
                div.textContent = '';
            });
        });
        
    }

    useEffect(() => {
        getTopicDescription()
        getDefaultValue();
        filters.reporttype = 'weekly'
        getAllLandingPages(filters.merchant);
        dispatch(getTopics(filters));
        if ('origin' in filters) {
            dispatch(getTopicsFlowAnalysisVisualizationData(filters));
        } else {
            filters.origin = 'utm_campaign';
            dispatch(getTopicsFlowAnalysisVisualizationData(filters));
        }
    }, [])

    function updateFilterValue(obj, reload = false) {
        dispatch({
            type: 'RESET_TOPIC_FLOW_ANALYSIS_VISUALIZATION',
        })
        dispatch(setFilters(obj))
        setFilterChange(true)
    }


    function handleOriginChangeSelection(e) {
        dispatch(setFilters({
            origin: e.value,
        }))
        setFilterChange(true)
    }

    function handleTopKChangeSelection(e) {
        if (e.target.value < 1) {return}
        setSelectTopK({value: e.target.value})
    }



    useEffect(() => {
        if (filterChange === true) {
            setFilterChange(false);
            dispatch({
                type: 'RESET_TOPIC_FLOW_ANALYSIS_VISUALIZATION',
            })
    
            getAllLandingPages(filters.merchant);
            dispatch(getTopicsFlowAnalysisVisualizationData(filters));

        }
    }, [filterChange])


    function getAllLandingPages(merchant) {
        var token = Cookies.get('token');
        fetch(`${process.env.REACT_APP_APIURL}/landing_pages`, {
            method: 'post',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
            body: JSON.stringify({ merchant_id: merchant }),
        }).then(response => response.json())
            .then(data => {
                const uniqueArray = Array.from(new Set(data));
                var list = []
                uniqueArray.map((item, index) => {
                    list.push({ "value": item, "label": item })
                })
                setLandingPage(list)
            })
            .catch((error) => {
                console.error(error);
            });
    }


    async function getMerchantConfiguration(merchant_value) {
        const config_values = await getUserConfig(merchant_value);
        if (config_values !== null && config_values !== undefined) {
            const primaryColor = config_values['primary_color'];
            const tertiaryColor = toGetColorCode(primaryColor, 3, 'white');
            const seventhColor = toGetColorCode(primaryColor, 7, 'white');
            const tenthColorShade = toGetColorCode(primaryColor, 9, 'white');
            const secondaryColor = toGetColorCode(primaryColor, 3, 'white');

            await setRequiredColorForPage({ ...requiredColorForPage, primaryColor: primaryColor, secondaryColor: secondaryColor, tertiaryColor: tertiaryColor, seventhColor: seventhColor, tenthColor: tenthColorShade, })
        }

        if (merchant_value.length === 0) {
            await setRequiredColorForPage({ ...requiredColorForPage, primaryColor: '', secondaryColor: '', seventhColor: '', tertiaryColor: '' })
        }
    }
    useEffect(() => {
        if (selectedMerchant !== 'All') {

            setRequiredColorForPage({ ...requiredColorForPage })
            // getMerchantConfiguration(customerConfig.configuration, selectedMerchant)
            // getMerchantConfiguration([])

            if (selectedMerchant === "") {
                updateFilterValue({
                    merchant: ""
                })
            } else {
                updateFilterValue({
                    merchant: [{ label: selectedMerchant, value: selectedMerchant }]
                })
            }

            const primaryColor = customerConfig.configuration && customerConfig.configuration['primary_color'];
            const secondaryColor = toGetColorCode(primaryColor, 3, 'white');
            const tertiaryColor = toGetColorCode(primaryColor, 5, 'white');
            const seventhColor = toGetColorCode(primaryColor, 7, 'white');
            const tenthColorShade = toGetColorCode(primaryColor, 9, 'white');

            setRequiredColorForPage({ primaryColor: primaryColor, secondaryColor: secondaryColor, seventhColor: seventhColor, tertiaryColor: tertiaryColor, tenthColor: tenthColorShade })
        } else {
            // getMerchantConfiguration({}, [])
            setRequiredColorForPage({ ...requiredColorForPage, primaryColor: '', secondaryColor: '', seventhColor: '', tertiaryColor: '', tenthColor: '' })
            updateFilterValue({
                merchant: ""
            })
        }

    }, [selectedMerchant, customerConfig.configuration, selectedTimezone])
      

    const colorStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            if (isSelected) {
                return {
                    ...styles,
                    backgroundColor: requiredColorForPage.primaryColor ? requiredColorForPage.primaryColor : 'transparent',
                    color: requiredColorForPage.primaryColor ? '#FFFFFF' : '#000000',
                };
            }
            else if (isFocused) {
                return {
                    ...styles,
                    backgroundColor: requiredColorForPage.seventhColor ? requiredColorForPage.seventhColor : 'transparent',
                    color: '#000000',
                };
            }
            else {
                return {
                    ...styles
                };
            }
        },
    };

    function getDefaultValue() {
        let selected = filters.origin;
        if (selected !== '') {
            
            originOptions.forEach((options,i) => {
                if (options.value === selected) {
                    setDefaultOrigin(i);
                }
            })    
        }
    }

    async function handleResetFilters() {
        dispatch({
            type: 'RESET_FILTERS',
            payload: {},
        })
        updateFilterValue({origin:filters.origin})
    }    

    return (
        <div style={{ overflowY: 'scroll' }}>
            <div className="abc mx-auto px-2 ">
                <Filters
                    filters={filters}
                    setFilters={(obj) => updateFilterValue(obj)}
                    landingPage={landingPage}
                    total={total}
                    filterChange={filterChange}
                    merchantColors={requiredColorForPage}
                    isSelectedMerchant={isSelectedMerchant}
                />
            </div>
            <div className='flex start'>
                <div className=" w-1/5 pt-6 pb-0 mr-5">
                    <div className='flex items-center z-0'>
                        <p className="mr-3 font-poppins font-medium text-sm text-secondaryColor pb-1">Origin: </p>
                        {defaultOrigin !==0 &&
                        <Rselect 
                            originOptions={originOptions}
                            defaultOrigin={defaultOrigin}
                            onchange={(e) => { handleOriginChangeSelection(e) }}
                            styles={colorStyles}
                        />
                        }
                        {defaultOrigin === 0 &&
                            <Rselect 
                                originOptions={originOptions}
                                defaultOrigin={defaultOrigin}
                                onchange={(e) => { handleOriginChangeSelection(e) }}
                                styles={colorStyles}
                            />
                        }
                    </div>

                </div>
                <div className="w-1/5 pt-6 pb-0">
                    <div className='flex items-center'>
                        <p className='mr-4 font-poppins font-medium text-sm text-secondaryColor pb-1'>Topic Count:</p>
                        <input name="colors" min="1" type="number" defaultValue={selectTopK.value} onChange={(e) => { handleTopKChangeSelection(e) }} className="border border-gray-400 rounded p-1 my-1 text-black w-1/3" />
                    </div>

                </div>
            </div>
            <div className='mt-4'>
                <ListItemCount total={total} merchantColors={requiredColorForPage}/>
            </div>
            {topicDataLoading === true &&
                <div className="loading-container"> <div className="loading-div"></div></div>
            }
            {
                (total === 0 && topicDataLoading === false) &&
                <NoResultFound
                  resetFilters={handleResetFilters}
                  merchantColors={requiredColorForPage}
                  isSelectedMerchant={isSelectedMerchant}
                />
            }

            <div className='mt-8 mx-2 rounded relative' style={{ width: '3000px', height: '2000px', overflow: 'scroll' }} >
                <div className="tooltip-text">
                    <div className="tooltip_text"></div>
                </div>
                <svg id="sankey" ref={sankeyDiagramDiv} style={{ background: 'transparent', border: 'none', width: '500%', height: '100%' }}>
                </svg>
            </div>
        </div>
    );
};