export const timezones = [
    {
        "value": "Pacific/Pago_Pago",
        "label": "(GMT-11:00) Pago Pago"
    },
    {
        "value": "Pacific/Honolulu",
        "label": "(GMT-10:00) Hawaii Time"
    },
    {
        "value": "America/Los_Angeles",
        "label": "(GMT-08:00) Pacific Time"
    },
    {
        "value": "America/Tijuana",
        "label": "(GMT-08:00) Pacific Time - Tijuana"
    },
    {
        "value": "America/Denver",
        "label": "(GMT-07:00) Mountain Time"
    },
    {
        "value": "America/Phoenix",
        "label": "(GMT-07:00) Mountain Time - Arizona"
    },
    {
        "value": "America/Mazatlan",
        "label": "(GMT-07:00) Mountain Time - Chihuahua, Mazatlan"
    },
    {
        "value": "America/Chicago",
        "label": "(GMT-06:00) Central Time"
    },
    {
        "value": "America/Mexico_City",
        "label": "(GMT-06:00) Central Time - Mexico City"
    },
    {
        "value": "America/Regina",
        "label": "(GMT-06:00) Central Time - Regina"
    },
    {
        "value": "America/Guatemala",
        "label": "(GMT-06:00) Guatemala"
    },
    {
        "value": "America/Bogota",
        "label": "(GMT-05:00) Bogota"
    },
    {
        "value": "America/New_York",
        "label": "(GMT-05:00) Eastern Time"
    },
    {
        "value": "America/Lima",
        "label": "(GMT-05:00) Lima"
    },
    {
        "value": "America/Caracas",
        "label": "(GMT-04:30) Caracas"
    },
    {
        "value": "America/Halifax",
        "label": "(GMT-04:00) Atlantic Time - Halifax"
    },
    {
        "value": "America/Guyana",
        "label": "(GMT-04:00) Guyana"
    },
    {
        "value": "America/La_Paz",
        "label": "(GMT-04:00) La Paz"
    },
    {
        "value": "America/Argentina/Buenos_Aires",
        "label": "(GMT-03:00) Buenos Aires"
    },
    {
        "value": "America/Godthab",
        "label": "(GMT-03:00) Godthab"
    },
    {
        "value": "America/Montevideo",
        "label": "(GMT-03:00) Montevideo"
    },
    {
        "value": "America/St_Johns",
        "label": "(GMT-03:30) Newfoundland Time - St. Johns"
    },
    {
        "value": "America/Santiago",
        "label": "(GMT-03:00) Santiago"
    },
    {
        "value": "America/Sao_Paulo",
        "label": "(GMT-02:00) Sao Paulo"
    },
    {
        "value": "Atlantic/South_Georgia",
        "label": "(GMT-02:00) South Georgia"
    },
    {
        "value": "Atlantic/Azores",
        "label": "(GMT-01:00) Azores"
    },
    {
        "value": "Atlantic/Cape_Verde",
        "label": "(GMT-01:00) Cape Verde"
    },
    {
        "value": "Africa/Casablanca",
        "label": "(GMT+00:00) Casablanca"
    },
    {
        "value": "Europe/Dublin",
        "label": "(GMT+00:00) Dublin"
    },
    {
        "value": "Europe/Lisbon",
        "label": "(GMT+00:00) Lisbon"
    },
    {
        "value": "Europe/London",
        "label": "(GMT+00:00) London"
    },
    {
        "value": "Africa/Monrovia",
        "label": "(GMT+00:00) Monrovia"
    },
    {
        "value": "Africa/Algiers",
        "label": "(GMT+01:00) Algiers"
    },
    {
        "value": "Europe/Amsterdam",
        "label": "(GMT+01:00) Amsterdam"
    },
    {
        "value": "Europe/Berlin",
        "label": "(GMT+01:00) Berlin"
    },
    {
        "value": "Europe/Brussels",
        "label": "(GMT+01:00) Brussels"
    },
    {
        "value": "Europe/Budapest",
        "label": "(GMT+01:00) Budapest"
    },
    {
        "value": "Europe/Belgrade",
        "label": "(GMT+01:00) Central European Time - Belgrade"
    },
    {
        "value": "Europe/Prague",
        "label": "(GMT+01:00) Central European Time - Prague"
    },
    {
        "value": "Europe/Copenhagen",
        "label": "(GMT+01:00) Copenhagen"
    },
    {
        "value": "Europe/Madrid",
        "label": "(GMT+01:00) Madrid"
    },
    {
        "value": "Europe/Paris",
        "label": "(GMT+01:00) Paris"
    },
    {
        "value": "Europe/Rome",
        "label": "(GMT+01:00) Rome"
    },
    {
        "value": "Europe/Stockholm",
        "label": "(GMT+01:00) Stockholm"
    },
    {
        "value": "Europe/Vienna",
        "label": "(GMT+01:00) Vienna"
    },
    {
        "value": "Europe/Warsaw",
        "label": "(GMT+01:00) Warsaw"
    },
    {
        "value": "Europe/Athens",
        "label": "(GMT+02:00) Athens"
    },
    {
        "value": "Europe/Bucharest",
        "label": "(GMT+02:00) Bucharest"
    },
    {
        "value": "Africa/Cairo",
        "label": "(GMT+02:00) Cairo"
    },
    {
        "value": "Asia/Jerusalem",
        "label": "(GMT+02:00) Jerusalem"
    },
    {
        "value": "Africa/Johannesburg",
        "label": "(GMT+02:00) Johannesburg"
    },
    {
        "value": "Europe/Helsinki",
        "label": "(GMT+02:00) Helsinki"
    },
    {
        "value": "Europe/Kiev",
        "label": "(GMT+02:00) Kiev"
    },
    {
        "value": "Europe/Kaliningrad",
        "label": "(GMT+02:00) Moscow-01 - Kaliningrad"
    },
    {
        "value": "Europe/Riga",
        "label": "(GMT+02:00) Riga"
    },
    {
        "value": "Europe/Sofia",
        "label": "(GMT+02:00) Sofia"
    },
    {
        "value": "Europe/Tallinn",
        "label": "(GMT+02:00) Tallinn"
    },
    {
        "value": "Europe/Vilnius",
        "label": "(GMT+02:00) Vilnius"
    },
    {
        "value": "Europe/Istanbul",
        "label": "(GMT+03:00) Istanbul"
    },
    {
        "value": "Asia/Baghdad",
        "label": "(GMT+03:00) Baghdad"
    },
    {
        "value": "Africa/Nairobi",
        "label": "(GMT+03:00) Nairobi"
    },
    {
        "value": "Europe/Minsk",
        "label": "(GMT+03:00) Minsk"
    },
    {
        "value": "Asia/Riyadh",
        "label": "(GMT+03:00) Riyadh"
    },
    {
        "value": "Europe/Moscow",
        "label": "(GMT+03:00) Moscow+00 - Moscow"
    },
    {
        "value": "Asia/Tehran",
        "label": "(GMT+03:30) Tehran"
    },
    {
        "value": "Asia/Baku",
        "label": "(GMT+04:00) Baku"
    },
    {
        "value": "Europe/Samara",
        "label": "(GMT+04:00) Moscow+01 - Samara"
    },
    {
        "value": "Asia/Tbilisi",
        "label": "(GMT+04:00) Tbilisi"
    },
    {
        "value": "Asia/Yerevan",
        "label": "(GMT+04:00) Yerevan"
    },
    {
        "value": "Asia/Kabul",
        "label": "(GMT+04:30) Kabul"
    },
    {
        "value": "Asia/Karachi",
        "label": "(GMT+05:00) Karachi"
    },
    {
        "value": "Asia/Yekaterinburg",
        "label": "(GMT+05:00) Moscow+02 - Yekaterinburg"
    },
    {
        "value": "Asia/Tashkent",
        "label": "(GMT+05:00) Tashkent"
    },
    {
        "value": "Asia/Colombo",
        "label": "(GMT+05:30) Colombo"
    },
    {
        "value": "Asia/Almaty",
        "label": "(GMT+06:00) Almaty"
    },
    {
        "value": "Asia/Dhaka",
        "label": "(GMT+06:00) Dhaka"
    },
    {
        "value": "Asia/Rangoon",
        "label": "(GMT+06:30) Rangoon"
    },
    {
        "value": "Asia/Bangkok",
        "label": "(GMT+07:00) Bangkok"
    },
    {
        "value": "Asia/Jakarta",
        "label": "(GMT+07:00) Jakarta"
    },
    {
        "value": "Asia/Krasnoyarsk",
        "label": "(GMT+07:00) Moscow+04 - Krasnoyarsk"
    },
    {
        "value": "Asia/Shanghai",
        "label": "(GMT+08:00) China Time - Beijing"
    },
    {
        "value": "Asia/Hong_Kong",
        "label": "(GMT+08:00) Hong Kong"
    },
    {
        "value": "Asia/Kuala_Lumpur",
        "label": "(GMT+08:00) Kuala Lumpur"
    },
    {
        "value": "Asia/Irkutsk",
        "label": "(GMT+08:00) Moscow+05 - Irkutsk"
    },
    {
        "value": "Asia/Singapore",
        "label": "(GMT+08:00) Singapore"
    },
    {
        "value": "Asia/Taipei",
        "label": "(GMT+08:00) Taipei"
    },
    {
        "value": "Asia/Ulaanbaatar",
        "label": "(GMT+08:00) Ulaanbaatar"
    },
    {
        "value": "Australia/Perth",
        "label": "(GMT+08:00) Western Time - Perth"
    },
    {
        "value": "Asia/Yakutsk",
        "label": "(GMT+09:00) Moscow+06 - Yakutsk"
    },
    {
        "value": "Asia/Seoul",
        "label": "(GMT+09:00) Seoul"
    },
    {
        "value": "Asia/Tokyo",
        "label": "(GMT+09:00) Tokyo"
    },
    {
        "value": "Australia/Darwin",
        "label": "(GMT+09:30) Central Time - Darwin"
    },
    {
        "value": "Australia/Brisbane",
        "label": "(GMT+10:00) Eastern Time - Brisbane"
    },
    {
        "value": "Pacific/Guam",
        "label": "(GMT+10:00) Guam"
    },
    {
        "value": "Asia/Magadan",
        "label": "(GMT+10:00) Moscow+07 - Magadan"
    },
    {
        "value": "Asia/Vladivostok",
        "label": "(GMT+10:00) Moscow+07 - Yuzhno-Sakhalinsk"
    },
    {
        "value": "Pacific/Port_Moresby",
        "label": "(GMT+10:00) Port Moresby"
    },
    {
        "value": "Australia/Adelaide",
        "label": "(GMT+10:30) Central Time - Adelaide"
    },
    {
        "value": "Australia/Hobart",
        "label": "(GMT+11:00) Eastern Time - Hobart"
    },
    {
        "value": "Australia/Sydney",
        "label": "(GMT+11:00) Eastern Time - Melbourne, Sydney"
    },
    {
        "value": "Pacific/Guadalcanal",
        "label": "(GMT+11:00) Guadalcanal"
    },
    {
        "value": "Pacific/Noumea",
        "label": "(GMT+11:00) Noumea"
    },
    {
        "value": "Pacific/Majuro",
        "label": "(GMT+12:00) Majuro"
    },
    {
        "value": "Asia/Kamchatka",
        "label": "(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy"
    },
    {
        "label": "(GMT+13:00) Auckland",
        "value": "Pacific/Auckland"
    },
    {
        "label": "(GMT+13:00) Fakaofo",
        "value": "Pacific/Fakaofo"
    },
    {
        "label": "(GMT+13:00) Fiji",
        "value": "Pacific/Fiji"
    },
    {
        "label": "(GMT+13:00) Tongatapu",
        "value": "Pacific/Tongatapu"
    },
    {
        "label": "(GMT+14:00) Apia",
        "value": "Pacific/Apia"
    }
]