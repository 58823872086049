import {useEffect, useState, React} from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Cookies from 'js-cookie';

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import ChatLayout from "layouts/chat";
import ChatErrorLayout from "layouts/chat_error";
import { useNavigate } from 'react-router-dom';

const App = () => {

  const navigage = useNavigate();
  const [isLogged, setIsLogged] = useState(false)
  const location = useLocation(); 

  async function getToken() {
    const tokenString = Cookies.get('token');
    let isToken = false;
    if(tokenString !== "" && tokenString !== undefined && tokenString !== null) {
      setIsLogged(true)
      isToken = true;
    }
    if((location.pathname !== '/auth/sign-in' 
    && location.pathname !== '/auth/forgot_password' 
    && (location.pathname.indexOf('reset_password') === -1)) && !isToken) {
      navigage("/auth/sign-in")
    }
    if (location.pathname === '/auth/sign-in' && isToken) {
      navigage("/")
    }
    
    
  }

  useEffect(() => {
    getToken();   
  }, []);

  return (
      <>
      {isLogged && 
        <Routes>
            <Route path="admin/*" element={<AdminLayout />}  />
            <Route path="chat/*" element={<ChatLayout />} />
            <Route path="chat_error/*" element={<ChatErrorLayout />} />
            <Route path="/" element={<Navigate to="/admin" replace />} />
        </Routes>
      }
      {!isLogged &&
       <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route path="/" element={<AuthLayout />} />
      </Routes>
      }
      </>
);
};

export default App;
