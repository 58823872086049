import React from "react";
import Card from "components/card";
import InfiniteScroll from 'react-infinite-scroll-component';
import { changeTimezone } from 'utils/utils';

function formateColumnArrayToColumnName(columns) {
    const columnsName = columns.map((col) => col.label)
    return columnsName;
}
  


const CheckTable = (props) => {
    const { columnsData, tableData, columnsToShow, totalRowsToDisplay, fetchMoreData, hasMoreDataToLoad, merchantColors, page, selectedTimezone, isSelectedMerchant } = props;
    console.log("tableData", tableData)
    console.log("totalRowsToDisplay", totalRowsToDisplay)
    
    // Function to replace words
    function replaceWords(text) {
        if (text !== undefined) {
            return text.replace(/scheduled/g, 'sch').replace(/requested/g, 'req').replace(/clicked/g, 'clk').replace(/submitted/g, 'sub').replace(/UserUnresponsive/g, '').replace(/Callback/g, 'CallBk').replace(/Meeting/g, 'Mtg');
        }
        else {
            return text;
        }
    }

    const columns = formateColumnArrayToColumnName(columnsToShow)
    const firstarray = tableData[0];
    const newFilteredData = tableData.reduce((acc, curr) =>{
        if(!acc.find((row) => row.conversation_id === curr.conversation_id)){
            acc.push(curr)
        }
        return acc;
    }, [])

    return (
        <Card extra={"w-full h-full sm:overflow-auto shadow-none rounded-none mt-1 relative"}>
            {props.isLoading &&
                <div className="loading-container"> <div className="loading-div"></div></div>
            }
            <div className="pt-4">
                <InfiniteScroll
                    dataLength={page*25}
                    next={() => {if(props.total > (page * 25)){fetchMoreData()}}}
                    // style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
                    // inverse={true} //
                    // hasMore={hasMoreDataToLoad}
                    // loader={<h4>Loading...</h4>}
                    // pullDownToRefreshThreshold={50}
                    hasMore={true}
                    scrollableTarget="scrollableDiv"
                    height={600}
                    // scrollThreshold={1}
                    endMessage={<p>No more data to load.</p>}
                    useWindow={false}
                >
                    <div id="scrollableDiv" className="">
                        <table
                            className="w-full tablelist"
                            variant="simple"
                            color="gray-500"
                            mb="24px"
                        >
                            <thead>
                                <tr
                                className="sticky top-0 z-20"
                                >
                                    <th
                                        className="pl-[2px] text-center sticky left-0 z-10 w-[50px] border-none"
                                    >
                                        <div className="text-s px-[10px] text-[#555555] rounded text-sm font-medium text-[#555555] font-poppins leading-[30px] py-1 whitespace-nowrap bg-[#ECECEC]">
                                            No.
                                        </div>
                                    </th>
                                    <th
                                        className="pl-[2px] text-center sticky left-[44px] z-10 w-[150px] border-none"
                                    >
                                        <div className="text-s px-[10px] text-[#555555] rounded text-sm font-medium text-[#555555] font-poppins leading-[30px] py-1 whitespace-nowrap bg-[#ECECEC]">
                                            <a className={`sorting ${props.filters.sorting === 'i.conversation_id' ? props.filters.sort : ''}`} href="#" onClick={() => props.sortFunction('i.conversation_id')}>Conversation #</a>
                                        </div>
                                    </th>
                                    {
                                        columnsToShow.length !== 0 && columnsToShow.map((key, index) => {
                                                return (
                                                    <th className="pl-[2px] text-center border-none">
                                                        <div className="text-s px-[10px] text-[#555555]  rounded text-sm font-medium text-[#555555] font-poppins leading-[30px] py-1 whitespace-nowrap bg-[#ECECEC]">
                                                            <a className={`sorting ${props.filters.sorting === key.value ? props.filters.sort : ''}`} href="#" onClick={() => props.sortFunction(key.value)}>{key.label}</a>
                                                        </div>
                                                    </th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>

                            <tbody>
                                {newFilteredData.map((row, index) => (
                                    <tr data-index={index+ 1} key={index}>
                                        <td 
                                            className="pl-2 text-sm bg-[#FBEFFC] sticky z-10 left-0" 
                                            style={{ 
                                                borderLeft: '1px solid #ececec',
                                                background: merchantColors.seventhColor && merchantColors.seventhColor !=='#ffffff' ? merchantColors.seventhColor : '#ECECEC', 
                                                color: merchantColors.primaryColor ? merchantColors.primaryColor : '#AC246E'
                                                }}
                                                >
                                            {(index + 1)}
                                        </td>
                                        <td
                                            style={{ 
                                                background: merchantColors.seventhColor && merchantColors.seventhColor !== '#ffffff' ? merchantColors.seventhColor : '#ECECEC', 
                                                color: merchantColors.primaryColor || "#AC246E" 
                                                }} 

                                            className="pl-2 text-sm sticky left-[47px] z-10 w-[150px]">
                                                <a 
                                                    href={`/chat/conversation?cid=${row.conversation_id}&mid=${row.merchant_id}`} target="_blank" 
                                                    // className="text-blueSecondary" 
                                                    // style={{color: merchantColors.primaryColor || "#AC246E"}}
                                                    rel="noreferrer">
                                                    {row.conversation_id.slice(-6)} {/***** */ }
                                                </a>
                                        </td>
                                        {
                                            columnsToShow.length !== 0 && columnsToShow.map((key, index) => {
                                                let column_value = row[key.value]
                                                if (key.value === 'logtime' || key.value === 'last_logtime') {
                                                    column_value = row[key.value]
                                                }
                                                if (key.value === 'conv_outcome') {
                                                    column_value = '<span title="'+row[key.value]+'" class="cursor-pointer">'+replaceWords(row[key.value])+'</span>'
                                                }
                                                return (
                                                    <td key={index} className="pl-2 text-sm truncate">
                                                            <span dangerouslySetInnerHTML={{ __html: column_value }}></span>
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </InfiniteScroll>
            </div>
        </Card>
    );
};

export default CheckTable;
