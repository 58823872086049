import { SET_ALL_CONVERSATIONS, SET_CONVERSATION_ENGAGED_LIST, SET_ERRORS_LIST, SET_FILTERS, SET_MERCHANTS_LIST, SET_USERS_LIST, SET_SELECTED_VALUE, SET_SELECTED_TIMEZONE, SET_TOPIC_ANALYSIS_VISUALIZATION, SET_TOPIC_SELECTION_FOR_VISUALIZATION, SET_TOPIC_SELECTED_FOR_VISUALIZATION, SET_TOPIC_TREND_ANALYSIS_VISUALIZATION, SET_TOPIC_SELECTION_FOR_TREND_ANALYSIS_VISUALIZATION, SET_TOPIC_SELECTED_FOR_TREND_ANALYSIS_VISUALIZATION, RESET_ALL_CONVERSATIONS,SET_TOPIC_FLOW_ANALYSIS_VISUALIZATION, RESET_FILTERS, RESET_CONVERSATION_ENGAGED_LIST, GET_CUSTOMER_CONFIG, RESET_TOPIC_FLOW_ANALYSIS_VISUALIZATION, SET_LOADING_LIST} from "./actionTypes";

const currentTimeMillis = new Date().getTime();
const currentDate = new Date(currentTimeMillis);

const tableHeaders = [
    { label: "Conversation #", key: "conversation_id" },
    { label: "Device", key: "device_type" },
    { label: "Date Created", key: "logtime" },
    { label: "Date Updated", key: "last_logtime" },
    { label: "Outcome", key: "conv_outcome" },
    { label: "Turns", key: "turn_user" },
    { label: "Response Time", key: "conv_rate" },
    { label: "UTM Source", key: "utm_source" },
    { label: "UTM Medium", key: "utm_medium" },
    { label: "UTM Term", key: "utm_term" },
    { label: "UTM Campaign", key: "utm_campaign" },
    { label: "Referrer", key: "referrer_id" },
    { label: "Page URL", key: "landing_page" }, //***** */
    { label: "Google Click ID", key: "gclid" },
    { label: "Avg User Words/Turn", key: "avg_total_user_words" },
    { label: "Avg Agent Words/Turn", key: "avg_total_bot_words" },
    { label: "Tot. Agent Words", key: "total_words_in_message_bot" },
    { label: "Lead #", key: "marketo_id" }, //***** */
    { label: "Appt Date", key: "appt_date" },
    { label: "Calendly", key: "leads" },
    { label: "Chat Rating", key: "user_rating" },
    { label: "Turns with Rating", key: "total_turn_rating" },
    { label: "Turns with Feedback", key: "total_turn_feedback" },
    { label: "User #", key: "su_id" },
    { label: "Session #", key: "session_id" }
  ]

  const feedbackTableHeaders = [
    { label: "Conversation #", key: "conversation_id" },
    { label: "Date/Time", key: "logtime" },
    { label: "User Turn", key: "user_turn" },
    { label: "Agent Answer", key: "agent_turn" },
    { label: "Rating", key: "user_turn_rating" },
    { label: "Feedback", key: "user_turn_feedback" },
    { label: "Link", key: "link" }
  ]

  const transcriptTableHeaders = [
    { label: "Conversation #", key: "conversation_id" },
    { label: "Device", key: "device_type" },
    { label: "Date Created", key: "logtime" },
    { label: "Date Updated", key: "last_logtime" },
    { label: "Outcome", key: "conv_outcome" },
    { label: "User Message", key: "user_message" },
    { label: "Bot Response", key: "bot_message" },
    { label: "Turn Time", key: "turn_time" },
    { label: "Chat Rating", key: "user_rating" },
    { label: "Turns", key: "turn_user" },
    { label: "Response Time", key: "conv_rate" },
    { label: "Appt Date", key: "appt_date" },
    { label: "Lead #", key: "marketo_id" }, //***** */
    { label: "UTM Source", key: "utm_source" },
    { label: "UTM Medium", key: "utm_medium" },
    { label: "UTM Term", key: "utm_term" },
    { label: "UTM Campaign", key: "utm_campaign" },
    { label: "Referrer", key: "referrer_id" },
    { label: "Page URL", key: "landing_page" },
    { label: "Avg User Words/Turn", key: "avg_total_user_words" },
    { label: "Avg Agent Words/Turn", key: "avg_total_bot_words" },
    { label: "Tot. Agent Words", key: "total_words_in_message_bot" },
    { label: "Calendly", key: "leads" },
    { label: "User #", key: "su_id" },
    { label: "Session #", key: "session_id" },
    { label: "Google Click ID", key: "gclid" },
    { label: "Turns with Rating", key: "total_turn_rating" },
    { label: "Turns with Feedback", key: "total_turn_feedback" }
  ]

const filters = {
    startDate: new Date(currentDate - (30 * 24 * 60 * 60 * 1000)),
    endDate: currentDate,
    startTime: currentDate.getUTCHours() + ":" + currentDate.getUTCMinutes(),
    endTime: currentDate.getUTCHours() + ":" + currentDate.getUTCMinutes(),
    conversationId: [],
    suId: [],
    turnID: {},
    convT2A: {},
    convOutcome: [],
    MarketoLead: [],
    chatRating: {},
    chatFeedback: [],
    apptDate: { from: '', to: '' },
    utm_source: [],
    utm_medium: [],
    utm_campaign: [],
    utm_placement: [],
    utm_term: [],
    utm_content: [],
    utm_adplacement: [],
    utm_match: [],
    utm_location: [],
    utm_target: [],
    gclid: [],
    device_type: [],
    landingpage: [],
    merchant: '',
    sort: "DESC",
    timezone: "+00:00",
    sorting: 'logtime',
    reporttype: 'weekly',
    topic: [],
    su_id: [],
    conversation_id: [],
    turn_rating: {},
    turn_feedback: {}
}


const initialState = {
    // SLICES ON THE BASIS OF SIDE BAR ROUTES
    default: {
        conversations: null,
        total: 0,
        isLoading: true,
    },
    chatEngaged: {
        conversations: null,
        total: 0,
        isLoading: true,
    },
    chatConversation: {
        conversations: null,
        total: 0,
        isLoading: true,
    },
    chatRating: {
        conversations: null,
        total: 0,
        isLoading: true,
    },
    leadsPerThousandVisitors: {
        conversations: null,
        total: 0,
        isLoading: true,
    },
    timeToAnswer: {
        conversations: null,
        total: 0,
        isLoading: true,
    },
    turnChat: {
        conversations: null,
        total: 0,
        isLoading: true,
    },
    goalConversion: {
        conversations: null,
        total: 0,
        isLoading: true,
    },
    errorsReport: {
        errors: null,
        total: 0,
        columnsDataCheck: false,
        isLoading: true,
    },
    merchantsList: {
        merchants: null,
        total: 0,
        columnsDataCheck: false,
        isLoading: true,
    },
    usersList: {
        users: null,
        total: 0,
        columnsDataCheck: false,
        isLoading: true,
    },
    filters: filters,
    tableHeaders: tableHeaders,
    feedbackTableHeaders: feedbackTableHeaders,
    transcriptTableHeaders: transcriptTableHeaders,
    topics: {
        topicsSelectedToVisualize: [],
        topicSelectionsToVisualize: [],
        topicsData: {
            turn_count: 0,
            grouped_topics: [],
        },
    },
    topicTrends: {
        topicsSelectedToAnalyze: [],
        topicTrendsData: null,
        topicSelectionsToAnalyze: [],
    },
    topicFlow: {
        topicFlowData: {
            data: [],
            origins: {},
            origin_distribution: {},
            total_conversations: 0,
        },
        loading:true,
    },
    selectedValue:'',
    selectedTimeZone:'',
    customerConfig: {
        configuration : null,
        errors : null
    },
    loadingList:false
};

const locallyStoredFilters = JSON.parse(localStorage.getItem('filters'))
if (locallyStoredFilters != null) {
    let end_date = {date: currentDate, hours: currentDate.getUTCHours(), minutes:currentDate.getUTCMinutes()}
    if (locallyStoredFilters.timezone !== undefined && locallyStoredFilters.timezone !== null && locallyStoredFilters.timezone !== '' && locallyStoredFilters.timezone !== '+00:00') {
        var modified_end_date = new Date(currentDate.toLocaleString('en-US', { timeZone: locallyStoredFilters.timezone }));
        end_date = {date: modified_end_date, hours: modified_end_date.getHours(), minutes:modified_end_date.getMinutes()}
    }

    initialState.filters =  {
        ...initialState.filters,
        ...locallyStoredFilters,
        endDate: end_date.date,
        endTime: end_date.hours + ":" + end_date.minutes,
        startDate: new Date(locallyStoredFilters.startDate),
        su_id: locallyStoredFilters.suId,
        conversation_id: locallyStoredFilters.conversationId
    }
    if (locallyStoredFilters.merchant !== '' && locallyStoredFilters.merchant.length > 0){
        initialState.selectedValue = locallyStoredFilters.merchant[0].value;
    }
    if (locallyStoredFilters.timezone !== '' && locallyStoredFilters.timezone !== null){
        initialState.selectedTimeZone = locallyStoredFilters.timezone;
    }
}

export const rootReducer = (state = initialState, action) => {
    switch (action.type) {


        case RESET_CONVERSATION_ENGAGED_LIST:
            const _data = {
                ...state,
                [action.payload.data.page]: {
                    ...state[action.payload.data.page],
                    conversations:[
                    ],
                    total: 0,
                    isLoading: false
                }
            };
            return _data;

        case RESET_ALL_CONVERSATIONS:
            return {
                ...state,
                default: {
                    ...state.default,
                    conversations: [],
                    total: 0,
                }
            }
        case SET_ALL_CONVERSATIONS:
            let defaultStore
            if (action.payload.status === 'ERROR') {
                defaultStore = {
                    ...state,
                    default: {
                        ...state.default,
                        conversations: [],
                        total: 0,
                        isLoading: false,
                        error: true
                    }
                };
            } else {
                defaultStore = {
                    ...state,
                    default: {
                        ...state.default,
                        conversations: [
                            ...state.default.conversations || [],
                            ...action.payload.data.result[0].record
                        ],
                        total: action.payload.data.result[0].total,
                        isLoading: false,
                        error: false
                    }
                };
            }
            return defaultStore;
        
        case SET_LOADING_LIST:
            return {
                ...state, loadingList: action.payload
            }

        case SET_CONVERSATION_ENGAGED_LIST:

            let data;
            if (action.payload.status === "ERROR") {
                if (action.payload.data.page in state) {
                    data = {
                        ...state,
                        [action.payload.data.page]: {
                            ...state[action.payload.data.page],
                            conversations: [],
                            total: 0,
                            isLoading: false,
                            error: true
                        }
                    };
                }
            }

            if (action.payload.status === "SUCCESS") {
                if (action.payload.data.page in state) {
                    data = {
                        ...state,
                        [action.payload.data.page]: {
                            ...state[action.payload.data.page],
                            conversations:[
                                ...state[action.payload.data.page].conversations || [],
                                ...action.payload.data.result[0].record,
                            ],
                            total: action.payload.data.result[0].total,
                            isLoading: false
                        }
                    };
                }
            }
            return data;

            
    //    case RESET_CONVERSATION_ENGAGED_LIST:
        // const _data = {
        //     ...state,
        //     [action.payload.data.page]: {
        //         ...state[action.payload.data.page],
        //         conversations:[
        //         ],
        //         total: 0,
        //         isLoading: false
        //     }
        // };
        // return _data;
        
        case SET_ERRORS_LIST:
            let errorsReportStore;
            if (action.payload.status === "SUCCESS") {
                errorsReportStore = {
                    ...state,
                    errorsReport: {
                        ...state.errorsReport,
                        errors: action.payload.data.result[0].record,
                        total: action.payload.data.result[0].total,
                        columnsDataCheck: true,
                        isLoading: false
                    }
                };
            } else {
                errorsReportStore = {
                    ...state,
                    errorsReport: {
                        ...state.errorsReport,
                        errors: [], total: 0,
                        columnsDataCheck: false,
                        isLoading: false,
                        error: true
                    }
                };
            }
            return errorsReportStore;
        case SET_MERCHANTS_LIST:
            let merchantsListStore;
            if (action.payload.status === 'SUCCESS') {
                merchantsListStore = {
                    ...state,
                    merchantsList: {
                        ...state.merchantsList,
                        merchants: action.payload.data.result[0].record,
                        total: action.payload.data.result[0].total,
                        columnsDataCheck: true,
                        isLoading: false
                    }
                };
            } else {
                merchantsListStore = {
                    ...state,
                    merchantsList: {
                        ...state.merchantsList,
                        merchants: [],
                        total: 0,
                        columnsDataCheck: false,
                        isLoading: false,
                        error: true
                    }
                };
            }
            return merchantsListStore;
        case SET_USERS_LIST:
            let usersListsStore;
            if (action.payload.status === 'SUCCESS') {
                usersListsStore = {
                    ...state,
                    usersList: {
                        ...state.usersList,
                        users: action.payload.data.result[0].record,
                        total: action.payload.data.result[0].total,
                        columnsDataCheck: true,
                        isLoading: false
                    }
                };

            }else{
                usersListsStore = {
                    ...state,
                    usersList: {
                        ...state.usersList,
                        users: [],
                        total: 0,
                        columnsDataCheck: true,
                        isLoading: false,
                        error: true
                    }
                };
            }
            return usersListsStore;
        case 'UPDATE_CONVERSATION_ENGAGEMENT_LIST':
            let stateToUpdate = {
                ...state,
                [action.payload.dataIdentifier]: {
                    ...state[action.payload.dataIdentifier],
                    ...action.payload.toUpdate,
                }
            };
            return stateToUpdate;

        case 'UPDATE_ALL_CONVERSATION_LIST':
            let stateUpdate = {
                ...state,
                default: {
                    ...state.default,
                    ...action.payload.toUpdate
                }
            };
            return stateUpdate;

        case 'UPDATE_LOADING_STATE':

            return {
                ...state,
                [action.payload.dataIdentifier]: {
                    ...state[action.payload.dataIdentifier],
                    ...action.payload.toUpdate
                }
            };
        case SET_TOPIC_ANALYSIS_VISUALIZATION:
            return {
                ...state,
                topics: {
                    ...state.topics,
                    topicsData: action.payload.data,
                },
            };
        case SET_TOPIC_SELECTION_FOR_VISUALIZATION:
            return {    
                ...state,
                topics: {
                    ...state.topics,
                    topicSelectionsToVisualize: action.payload.data
                }
            }
        case SET_TOPIC_SELECTED_FOR_VISUALIZATION:
            return {
                ...state,
                topics: {
                    ...state.topics,
                    topicsSelectedToVisualize: action.payload.data
                }
            }
        case SET_TOPIC_TREND_ANALYSIS_VISUALIZATION:
            return {
                ...state,
                topicTrends: {
                    ...state.topicTrends,
                    topicTrendsData: action.payload.data,
                }
            }
        case SET_TOPIC_SELECTION_FOR_TREND_ANALYSIS_VISUALIZATION:
            return {
                ...state,
                topicTrends: {
                    ...state.topicTrends,
                    topicSelectionsToAnalyze: action.payload.data,
                }
            }
        case SET_TOPIC_SELECTED_FOR_TREND_ANALYSIS_VISUALIZATION:
            return {
                ...state,
                topicTrends: {
                    ...state.topicTrends,
                    topicsSelectedToAnalyze: action.payload.data,
                }
            }
        case SET_TOPIC_FLOW_ANALYSIS_VISUALIZATION:
            if (action.payload.status === "ERROR") {
                return {
                    ...state,
                    topicFlow: {
                        ...state.topicFlow,
                        topicFlowData: action.payload.data,
                        loading:false
                    }
                }
            }
            else {
                return {
                    ...state,
                    topicFlow: {
                        ...state.topicFlow,
                        topicFlowData: action.payload.data,
                        loading:false
                    }
                }
            }
        case RESET_TOPIC_FLOW_ANALYSIS_VISUALIZATION:
            return {
                ...state,
                topicFlow: {
                    ...state.topicFlow,
                    topicFlowData: {
                        data: [],
                        origins: {},
                        origin_distribution: {},
                        total_conversations: 0,
                    },
                    loading:true,
                }
            }
        case SET_FILTERS:
            const newFilters = { ...state.filters, ...action.payload }
            const dataAfterSetFilter = {
                ...state, filters: newFilters
            }
            localStorage.setItem('filters', JSON.stringify(newFilters))
            return dataAfterSetFilter

        case RESET_FILTERS:
            return {
                ...state, filters: {
                    ...filters,
                    merchant: state.filters.merchant,
                    timezone: state.filters.timezone
                },
            }

        case SET_SELECTED_VALUE:
            return {
                ...state, selectedValue: action.payload
            }
    
        case SET_SELECTED_TIMEZONE:
            return {
                ...state, selectedTimezone: action.payload
            }

        case GET_CUSTOMER_CONFIG:
            let customerConfig;
            if (action.payload.status === 'SUCCESS') {
                customerConfig = {
                    ...state,
                    customerConfig: {
                        ...state.customerConfig,
                        configuration: action.payload.data,
                        error: false
                    }
                }
            } else {
                customerConfig = {
                    ...state,
                    customerConfig: {
                        ...state.customerConfig,
                        configuration: {},
                        error: true
                    }
                }
            }
            return customerConfig;
        default:
            return state;
    }
} 