import { components } from "react-select";


function DropdownIndicator(props) {
    return (
        <components.DropdownIndicator {...props}>
            <span style={{ fontSize: '14px', color: "rgb(153, 153, 153)" }}>&#x25BE;</span>
        </components.DropdownIndicator>
    );
}

export default DropdownIndicator;
