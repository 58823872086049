import React from 'react';
import DeleteWarning from '../../assets/svg/deleteWarning.svg';

export default function DeleteConfirmation(props) {
  const { onClosePopup, dataToDelete, onDelete } = props;
  return (
    <div className='flex flex-col gap-8 p-10 bg-white items-center w-[25rem] h-[26.5rem] rounded shadow-[0_1px_4px_0px_rgba(0,0,0,0.25)] z-50 -translate-24' style={{position: 'absolute', 'top': '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>

      <img src={DeleteWarning} alt='icon'
        className='w-25 h-22'
      />
      <h4 className='font-semibold font-poppins text-2xl text-[#3B3B3B] mt-3 mb-0 leading-6'>Are you sure you want to delete user {dataToDelete.firstname}?</h4>
      <p className=' text-[#555555] text-base font-poppins font-medium leading-6 -mt-2 text-center'>You will not be able to restore User in the future</p>
      <div className='flex flex-row gap-5 mt-0 items-center justify-center'>
        <button
          onClick={() => {
            onClosePopup(false)
            onDelete(dataToDelete.id)
          }}
          className='w-32 h-12 bg-white border border-[#FBEFFC]  rounded text-[#3B3B3B] text-base font-poppins font-medium leading-6'
        >Yes, Delete
        </button>
        <button
          className='w-32 h-12 bg-[#6A1F70] rounded text-white text-base font-poppins font-medium leading-6'
          onClick={() => onClosePopup(false)}

          // onClick={onClosePopup(false)}
        >No, keep
        </button>

      </div>

    </div>
  )
}
