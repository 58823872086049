import React, { Component, useRef } from 'react';
import {useEffect, useState} from 'react';

// import ProfileIcon from '../../assets/svg/profileIcon.svg';
import ProfileIcon from '../../assets/svg/ProfileIconAvatar.svg';

import { Link } from 'react-router-dom';

import { getUserConfig } from 'utils/utils';
import toGetColorCode from 'hooks/useToGetColorCode';
import { useSelector } from 'react-redux';


const Header = (props) => {

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useRef(null);
  const selectedMerchant = useSelector((state) => state.selectedValue);
  const customerConfig = useSelector((state) => state.customerConfig);

  const primaryColor = customerConfig.configuration && customerConfig.configuration['primary_color'];
  const seventhColor = toGetColorCode(primaryColor, 7, 'white');

  const [requiredColorForPage, setRequiredColorForPage] = useState({
    primaryColor: primaryColor,
    seventhColor: seventhColor,
  })

  useEffect(() => {
    getAllValues();
  }, [])

  async function getAllValues() {
    var fname = localStorage.getItem('firstname')
    var lname = localStorage.getItem('lastname')
    if (fname !== undefined) {
      await setFirstname(fname);
    }
    if (lname !== undefined) {
      await setLastname(lname);
    }
  }

  function handleDropdownPopupClickOutside(event) {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleDropdownPopupClickOutside, true);
    return () => {
      document.removeEventListener('click', handleDropdownPopupClickOutside, true);
    }
  }, [])



  async function getMerchantConfiguration(merchant_value) {
    const config_values = await getUserConfig(merchant_value);
  
    if (config_values !== null && config_values !== undefined) {
      const primaryColor = config_values['primary_color'];
      const seventhColor = toGetColorCode(primaryColor, 7, 'white');

      await setRequiredColorForPage({ ...requiredColorForPage, primaryColor: primaryColor,  seventhColor: seventhColor,})
    }

    if(merchant_value.length === 0){
      await setRequiredColorForPage({ ...requiredColorForPage, primaryColor: '', seventhColor: '' })
    }
  }



  useEffect(() => {
    if (selectedMerchant !== 'All') {
      setRequiredColorForPage({ ...requiredColorForPage })
      // getMerchantConfiguration(customerConfig.configuration, selectedMerchant)
      // getMerchantConfiguration([])

      const primaryColor = customerConfig.configuration && customerConfig.configuration['primary_color'];
      const secondaryColor = toGetColorCode(primaryColor, 3, 'white');
      const tertiaryColor = toGetColorCode(primaryColor, 5, 'white');
      const seventhColor = toGetColorCode(primaryColor, 7, 'white');
      const tenthColorShade = toGetColorCode(primaryColor, 9, 'white');

      setRequiredColorForPage({ primaryColor: primaryColor, secondaryColor: secondaryColor, seventhColor: seventhColor, tertiaryColor: tertiaryColor, tenthColor: tenthColorShade })
    } else {
      // getMerchantConfiguration({}, [])
      setRequiredColorForPage({ ...requiredColorForPage, primaryColor: '', secondaryColor: '', seventhColor: '', tertiaryColor: '', tenthColor: '' })
    }

  }, [selectedMerchant, customerConfig.configuration])



  const colourStyles = {
    // control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected || isFocused ? requiredColorForPage.primaryColor : 'transparent',
        color: isSelected || isFocused ? '#FFFFFF' : requiredColorForPage.primaryColor,
      };
    },
  };

  return (
    <div className="fixed top-0 py-[26px] px-12 w-full z-50 bg-white shadow-4xl flex cursor-pointer" style={{maxHeight: '95px'}}>
      {props.logoUrl !== '' &&
       <img
        className="max-h-[50px] max-w-[150px]"
                src={props.logoUrl}
                alt="Logo"
              />
      }
      <div className="ml-auto flex gap-2 items-center" onClick={() => setShowDropdown(!showDropdown)}>
        <span className='w-8 h-8 flex justify-center items-center rounded-full'
          style={{ background: requiredColorForPage.seventhColor && requiredColorForPage.seventhColor !=='#ffffff' ? requiredColorForPage.seventhColor : '#FBEFFC'}}
        >
            <img src={ProfileIcon} alt='icon' className='h-6 w-6' />
        </span>
        <span>  {firstname} {lastname}</span>
      </div>

      {
        showDropdown ? <div
          ref={dropdownRef}
          className='absolute right-10 top-20'>
          <Dropdown />
        </div> : null
      }
    </div>
  );
};

export default Header;


export const Dropdown = () => {

  return <>
    <div className='w-48 bg-white rounder-md shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] flex flex-col gap-3 justify-center items-center p-3 rounded' style={{boxShadow: "0px 1px 4px 0px #00000040"}}>
      <div className='flex flex-row gap-6 items-center mt-2'>
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.69255 4.68623C9.69255 4.21967 10.0152 3.8151 10.4701 3.7113C11.73 3.42377 13.0215 3.43346 14.248 3.7133C14.7029 3.81709 15.0255 4.22166 15.0255 4.68824V5.64929C15.0255 6.41909 15.8589 6.90021 16.5255 6.51531L17.3593 6.03397C17.7637 5.80045 18.2759 5.87799 18.5932 6.22074C19.0167 6.67836 19.3956 7.18935 19.7195 7.7503C20.0433 8.31122 20.2964 8.89483 20.4809 9.49041C20.6192 9.93652 20.4302 10.4189 20.0257 10.6524L19.192 11.1338C18.5253 11.5187 18.5253 12.4809 19.192 12.8658L20.0243 13.3464C20.4284 13.5797 20.6174 14.0614 20.4799 14.5072C20.109 15.7093 19.4716 16.8326 18.5926 17.78C18.2753 18.122 17.7636 18.1992 17.3596 17.9659L16.5255 17.4844C15.8589 17.0995 15.0255 17.5806 15.0255 18.3504V19.3134C15.0255 19.78 14.7029 20.1846 14.248 20.2884C12.9881 20.5759 11.6966 20.5662 10.4701 20.2863C10.0152 20.1826 9.69255 19.778 9.69255 19.3114V18.3503C9.69255 17.5805 8.85922 17.0994 8.19255 17.4843L7.35882 17.9657C6.95436 18.1992 6.44213 18.1216 6.12491 17.7789C5.70139 17.3213 5.32251 16.8103 4.99865 16.2494C4.67479 15.6884 4.42171 15.1048 4.23717 14.5092C4.09895 14.0631 4.28791 13.5807 4.69237 13.3472L5.52607 12.8659C6.19274 12.481 6.19274 11.5187 5.52607 11.1338L4.69379 10.6533C4.28972 10.42 4.10068 9.93829 4.23824 9.49244C4.60912 8.29037 5.24649 7.16705 6.12547 6.21966C6.4428 5.87763 6.95449 5.80049 7.35855 6.03378L8.19255 6.51529C8.85922 6.90019 9.69255 6.41907 9.69255 5.64927V4.68623ZM12.3597 14.4995C13.7402 14.4995 14.8594 13.3804 14.8594 11.9998C14.8594 10.6193 13.7402 9.50013 12.3597 9.50013C10.9791 9.50013 9.85998 10.6193 9.85998 11.9998C9.85998 13.3804 10.9791 14.4995 12.3597 14.4995Z" fill="#3B3B3B"/>
      </svg>
        <span onClick={ () => {
            console.log("settings is clicked")
          }
        }>Settings</span>
      </div>
      <div className='flex flex-row gap-6 items-center mt-2'>
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.369047 8.80907H8.76995C8.79181 8.83484 8.81446 8.86061 8.83632 8.88637C8.77307 8.91839 8.69577 8.93713 8.64814 8.98476C8.11093 9.51494 7.57763 10.049 7.0451 10.5839C6.68358 10.947 6.66484 11.4326 6.99591 11.7637C7.32152 12.0885 7.81812 12.0682 8.1773 11.7098C9.20877 10.6807 10.2402 9.65002 11.2694 8.61855C11.6567 8.23048 11.6567 7.76979 11.2694 7.38172C10.2309 6.34088 9.18925 5.30239 8.14997 4.26155C7.91729 4.02886 7.64009 3.95 7.32776 4.04057C7.02871 4.12724 6.84287 4.33807 6.77728 4.64259C6.71325 4.94164 6.81632 5.19073 7.02871 5.40311C7.56826 5.94032 8.10547 6.47987 8.64502 7.01708C8.6903 7.06237 8.74965 7.09438 8.80274 7.13186C8.79259 7.14904 8.78244 7.16622 8.77229 7.18339H0.37139C0.367485 7.12327 0.360458 7.0608 0.360458 6.99834C0.360458 5.74902 0.357335 4.4997 0.360458 3.25038C0.364362 1.64734 1.42707 0.35898 2.99809 0.0568006C3.20735 0.0161977 3.42442 0.00292367 3.63836 0.00292367C6.78743 -0.000199631 9.9365 -0.000980457 13.0856 0.00136202C14.9447 0.00292367 16.3541 1.40294 16.3564 3.25584C16.3604 6.42443 16.3611 9.59302 16.3564 12.7624C16.3541 14.3537 15.2883 15.6374 13.7266 15.9411C13.5299 15.9794 13.3261 15.9966 13.1254 15.9966C9.94977 15.9997 6.77494 16.002 3.59932 15.9974C2.00019 15.995 0.710268 14.9198 0.411992 13.3519C0.379198 13.1801 0.36202 13.0029 0.36202 12.8288C0.358115 11.5342 0.359677 10.2388 0.359677 8.94416C0.359677 8.9059 0.364362 8.86685 0.368266 8.80907H0.369047Z" fill="#BB4C3B"/>
      </svg>
        <Link to="/admin/logout">Logout</Link>
        {/* <span onClick={ () => {
          console.log("logout is clicked")
          history.push('/logout');
        }}>Log Out</span> */}
      </div>
    </div>
  </>
}