import Card from "components/card";
import { useNavigate } from 'react-router-dom';


const Widget = (props) => {
  const { icon, title, subtitle, url, tag, merchantColors, isSelectedMerchant } = props;
  const navigage = useNavigate();

  const handleDivClick = (url) => {
    // Redirect to the specific page
    if (url) {
      navigage(url);
    }
  };

  return (
 
      <div className="flex flex-row flex-grow items-center rounded shadow-5xl px-4 py-2 cursor-pointer w-[12rem]"
       onClick={() => handleDivClick(url)}>
        <div className="flex w-auto flex-row items-center">
        <div className="rounded  p-[6px] w-12 h-12 flex justify-center align-center" 
        style={{ 
          background: merchantColors.secondaryColor && merchantColors.secondaryColor !=='#ffffff' ? merchantColors.secondaryColor : '#BD88C1', 
          // background: merchantColors.secondaryColor ? merchantColors.secondaryColor : !isSelectedMerchant ? '#6A1F70' : '#FFFFFF',
          // border: isSelectedMerchant && !merchantColors.secondaryColor ? '1px solid #6A1F70' : 'none',
          }}>
            <span 
            className="flex items-center"
            // style={{
            //   // color: isSelectedMerchant && !merchantColors.secondaryColor ? '#6A1F70 !important' : '#ffffff',
            //   color :'blue'
            // }}
            >
              {icon}
            </span>
          </div>
        </div>

        <div className="h-50 ml-2 flex w-auto flex-col justify-center">
          <p className="font-dm text-xs font-medium text-secondaryColor font-poppins -pt-2">{title}</p>
          <p className="text-xl font-semibold text-secondaryColor leading-[36px] font-poppins dark:text-white flex item-end">
            {subtitle}
            <span>
          {
              tag && <span className="text-xs text-[#475569]" style={{fontSize: '8px', verticalAlign: 'middle', marginLeft: '6px'}}>({tag})</span>
            }
          </span>
          </p>
        </div>
      </div>
   

  );
};

export default Widget;